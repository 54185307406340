import React from 'react';
import "./addButton.css"; // We can reuse the styling
import Popup from "reactjs-popup";
import { GoTrash } from "react-icons/go";

export const DeleteButton = ({ entity, position, onDelete, selectedItems }) => {
  // Function to get the ID from an item regardless of entity type
  const getItemId = (item) => {
    // Check different possible ID field names
    return item.depot_id || item.route_id || item.id || item._id;
  };
  
  // Function to get the display name from an item
  const getItemName = (item) => {
    return item.name || item.raw_route_name || item.route_name || item.depot_name || "Unnamed";
  };

  const handleDelete = async (close) => {
    try {
      await onDelete();
      close();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <div className="btn-container">
      <Popup trigger={
        <button 
          className={`add-button-custom ${position}`} 
          disabled={!selectedItems?.length}
          style={{ 
            backgroundColor: !selectedItems?.length ? '#cccccc' : '#ff4444',
            cursor: !selectedItems?.length ? 'not-allowed' : 'pointer'
          }}
        >
          <GoTrash size={19} /> Delete {entity}
        </button>
      } modal nested>
        {close => (
          <div className="popup-container">
            <div className="close-container">
              <button className="close" onClick={() => close()}>&times;</button>
            </div>
            <div className="popup-content">
              <h3 className="popup-title">Delete {entity}{selectedItems?.length > 1 ? 's' : ''}</h3>
              <p>Are you sure you want to delete the following {entity.toLowerCase()}{selectedItems?.length > 1 ? 's' : ''}?</p>
              <div className="selected-items-list">
                {selectedItems?.map((item, index) => (
                  <p key={getItemId(item) || index}>
                    {index + 1}. {getItemName(item)}
                  </p>
                ))}
              </div>
            </div>
            <div className="add-button-container">
              <button 
                className="add-button-popup" 
                onClick={() => handleDelete(close)}
                style={{ backgroundColor: '#ff4444' }}
              >
                <GoTrash size={19} /> Delete
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}; 