import { Navigation } from '../components/website/navigation.jsx';
import { Header } from '../components/website/header.jsx';
import { Solutions } from "../components/website/Solutions.jsx";
import { Contact } from "../components/website/contact.jsx";

import React, { useState, useEffect } from 'react';
import JsonData from "../data/website_data.json";
import '../App.css';

function HomePage() {
    const [landingPageData, setLandingPagedata] = useState({});
    useEffect(() => {
        setLandingPagedata(JsonData);
    }, []);

    return (
        <div>
            <Navigation />
            <Header data={landingPageData.Header}/>
            <Solutions />
            <Contact data={landingPageData.Contact} />
        </div>
    );
};

export default HomePage;