import "./App.css";

import HomePage from "./pages/HomePage.js";

import SmoothScroll from "smooth-scroll";
import CalculatorHomePage from "./pages/CalculatorHomePage.js";
import LoginLanding from "./pages/LoginLandingPage.jsx";
import CreateAccount from "./pages/CreateAccount.js";
import ForgotPassword from "./pages/ForgotPassword.js";
import UnderConstruction from "./pages/UnderConstruction.js";
import ResetPassword from "./pages/ResetPassword.jsx";


import BasicCalculator from "./apps/fleet-planning/basicCalculator.js";
import FleetPlanningDashboard from "./apps/fleet-planning/fleetPlanningDashboard.js";
import Profile from "./apps/fleet-planning/profile.js";
import MyOperations from "./apps/fleet-planning/myOperations.js";
import Parameters from './apps/fleet-planning/parameters.js';
import Analytics from './apps/fleet-planning/analytics.js';
import Settings from "./apps/fleet-planning/settings.js";


import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { initGA, PageTracker, debugLog, GADebugger } from "./utils/analytics";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {
  useEffect(() => {
    initGA();
    if (process.env.NODE_ENV === "development") {
      debugLog("GA4 Initialized in debug mode");
    }
  }, []);

  return (
    <Router>
      <PageTracker />
      <GADebugger />
      <div>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/calculator" element={<CalculatorHomePage />} />
          <Route path="/fleet-planning-basic" element={<BasicCalculator />} />
          <Route path="/basic" element={<BasicCalculator />} />
          <Route path="/fleet-planning-pro" element={<FleetPlanningDashboard />} />
          <Route path="/pro" element={<FleetPlanningDashboard />} />
          <Route path="/login" element={<LoginLanding />} />
          <Route path="/create" element={<CreateAccount />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/construction" element={<UnderConstruction />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/my-operations" element={<MyOperations />} />
          <Route path="/parameters" element={<Parameters />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/settings" element={<Settings />} /> 
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
