import React, { useState } from "react";
import './css/AnalyticsRouteSelection.css';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend, ResponsiveContainer, ReferenceArea } from "recharts";
import { AnalyticsTooltip } from './common/AnalyticsTooltip';

export const AnalyticsRouteSelection = ({
    route,
    onSelect,
    isSelected
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 50, y: 50 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

    const ColoredCircle = ({ color }) => {
        const styles = { backgroundColor: color };
      
        return color ? (
          <React.Fragment>
            <span className="colored-circle" style={styles} />
          </React.Fragment>
        ) : null;
    };

    const handleClick = () => {
        // Toggle selection - if already selected, unselect it by passing null
        onSelect(isSelected ? null : route.route_id);
    };

    const showDetails = (e) => {
        e.stopPropagation();
        setShowPopup(true);
    };

    const closePopup = (e) => {
        e.stopPropagation();
        setShowPopup(false);
    };

    const handleMouseDown = (e) => {
        if (e.target.className === 'popup-header' || e.target.className === 'popup-drag-handle') {
            setIsDragging(true);
            setDragOffset({
                x: e.clientX - popupPosition.x,
                y: e.clientY - popupPosition.y
            });
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            setPopupPosition({
                x: e.clientX - dragOffset.x,
                y: e.clientY - dragOffset.y
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Prepare data for charts using the integrated data in the route object
    const prepareChartData = () => {
        if (!route || !route.charging_schedule || !route.battery_levels || !route.stop_ratios) {
            console.log("Missing route data, charging schedule, battery levels, or stop ratios");
            return [];
        }
        
        // Convert the object-based data to an array for the charts
        return Object.keys(route.charging_schedule).map((timePeriod) => {
            const chargingData = route.charging_schedule[timePeriod];
            const batteryData = route.battery_levels[timePeriod];
            const stopRatio = route.stop_ratios[timePeriod];
            
            // Calculate hours and minutes based on time period (assuming 15-minute intervals)
            const periodNum = parseInt(timePeriod);
            const totalMinutes = periodNum * 15;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            const timeOfDay = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            
            return {
                timePeriod,
                timeOfDay,
                chargingPower: chargingData.charging_power || 0,
                batteryLevel: batteryData.energy_level || 0,
                socPercentage: batteryData.soc_percentage || 0,
                electricityRate: chargingData.electricity_rate || 0,
                stopRatio: stopRatio,
                // Add charging location information
                stationId: chargingData.station_id,
                stationType: chargingData.station_type,
                stationName: chargingData.station_name,
                coordinates: chargingData.coordinates
            };
        }).filter(Boolean); // Remove any null entries
    };

    const chartData = prepareChartData();

    // Function to format time for display
    const formatTime = (timeString) => {
        if (!timeString) return "N/A";
        return timeString;
    };

    // Function to render the trip itinerary section
    const renderTripItinerary = () => {
        if (!route.trip_itinerary || !route.trip_itinerary.stops || route.trip_itinerary.stops.length === 0) {
            return <p>No trip itinerary available for this route.</p>;
        }

        return (
            <div className="trip-itinerary-section">
                <h3>Trip Itinerary</h3>
                {/* <p><strong>Start Time:</strong> {formatTime(route.trip_itinerary.start_time)}</p>
                <p><strong>End Time:</strong> {formatTime(route.trip_itinerary.end_time)}</p> */}
                <div className="itinerary-stops">
                    <table className="itinerary-table">
                        <thead>
                            <tr>
                                <th>Stop</th>
                                <th>Type</th>
                                <th>Address</th>
                                <th>Arrival</th>
                                <th>Departure</th>
                                <th>Stop Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {route.trip_itinerary.stops.map((stop) => (
                                <tr key={stop.stop_number}>
                                    <td>{stop.stop_number}</td>
                                    <td>{stop.type}</td>
                                    <td>{stop.address}</td>
                                    <td>{stop.type === 'Start' ? '-' : formatTime(stop.arrival_time)}</td>
                                    <td>{stop.type === 'End' ? '-' : formatTime(stop.departure_time)}</td>
                                    <td>{stop.duration_minutes > 0 ? `${stop.duration_minutes} min` : '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    return (
        <div className="analytics-route-item">
            <button 
                className={`route-selection ${isSelected ? "route-selected" : ""} ${route.is_electric === true ? "route-electric" : "route-diesel"}`}
                onClick={handleClick}
                type="button"
                aria-pressed={isSelected}
            >
                <span className="route-name">Route: {route.route_name}</span>
                <div className="route-details">
                    <span className="route-status">
                        Status: {route.is_electric === true 
                        ? <span style={{ color: 'green' }}><ColoredCircle color={'green'}/> Electric</span> 
                        : <span style={{ color: 'red' }}><ColoredCircle color={'red'}/> Diesel</span>}
                    </span>
                    {/* <span className="route-origin">
                        Origin: {route.origin}
                    </span>
                    <span className="route-destination">
                        Destination: {route.destination}
                    </span> */}
                    
                    <button 
                        className="show-details-button" 
                        onClick={showDetails}
                    >
                        Show Details
                    </button>
                </div>
            </button>

            {showPopup && (
                <div 
                    className="route-popup-overlay" 
                    onClick={closePopup}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                >
                    <div 
                        className="route-popup-content" 
                        onClick={e => e.stopPropagation()}
                        style={{
                            position: 'absolute',
                            left: `${popupPosition.x}px`,
                            top: `${popupPosition.y}px`,
                            cursor: isDragging ? 'grabbing' : 'auto',
                            // maxWidth: '50vw',
                            maxHeight: '90vh',
                            overflow: 'auto'
                        }}
                    >
                        <div 
                            className="popup-header"
                            onMouseDown={handleMouseDown}
                            style={{ cursor: 'grab', padding: '10px', background: '#f0f0f0', borderBottom: '1px solid #ddd' }}
                        >
                            <div className="popup-drag-handle" style={{ display: 'inline-block' }}>
                                <h2 style={{ margin: 0, display: 'inline-block' }}>Route {route.route_name} Details</h2>
                            </div>
                            <button className="close-popup" onClick={closePopup} style={{ float: 'right' }}>×</button>
                        </div>
                        
                        <div className="route-info">
                            {/* <p><strong>Origin:</strong> {route.origin}</p>
                            <p><strong>Destination:</strong> {route.destination}</p> */}
                            <p><strong>Status:</strong> <span style={{color: route.is_electric === true ? 'green' : 'red'}}>{route.is_electric === true ? "Electric" : "Diesel"}</span></p>
                            <p><strong>Truck Model:</strong> {route.is_electric === true 
                                ? (route.selected_model || "Not specified") 
                                : "Diesel Truck"}
                            </p>
                        </div>
                        
                        {/* Add the Trip Itinerary section */}
                        {renderTripItinerary()}
                        
                        {chartData.length > 0 ? (
                            <div className="charts-container">
                                <div className="chart-section">
                                    <h3>Charging Schedule</h3>
                                    <div style={{ position: 'relative', width: '100%', height: 200 }}>
                                        <ResponsiveContainer width="100%" height={200}>
                                            <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis 
                                                    dataKey="timeOfDay" 
                                                    type="category" 
                                                    allowDuplicatedCategory={false} 
                                                />
                                                <YAxis label={{ value: "kW", angle: -90, position: "insideLeft" }} />
                                                <Tooltip content={(props) => <AnalyticsTooltip {...props} chartType="charging" showStopInfo={true} />} />
                                                <Legend />
                                                {chartData.map((entry, index) => {
                                                    if (entry.stopRatio > 0 && index < chartData.length - 1) {
                                                        return (
                                                            <ReferenceArea 
                                                                key={`stop-${index}`}
                                                                x1={entry.timeOfDay} 
                                                                x2={chartData[index + 1].timeOfDay}
                                                                fill="#6b6b69"
                                                                fillOpacity={entry.stopRatio * 0.12} // Opacity based on stop ratio
                                                            />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Line 
                                                    type="stepAfter" 
                                                    dataKey="chargingPower" 
                                                    name="Charging Power" 
                                                    stroke="#ff7300" 
                                                    dot={false} 
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                        <div className="chart-legend" style={{ marginTop: '5px', fontSize: '12px', color: '#666', fontStyle: 'italic' }}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#6b6b69', opacity: 0.12, marginRight: '5px' }}></span>
                                                Shaded areas indicate when the truck is stopped
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="chart-section">
                                    <h3>Battery Level</h3>
                                    <div style={{ position: 'relative', width: '100%', height: 200 }}>
                                        <ResponsiveContainer width="100%" height={200}>
                                            <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis 
                                                    dataKey="timeOfDay" 
                                                    type="category" 
                                                    allowDuplicatedCategory={false} 
                                                />
                                                <YAxis label={{ value: "SoC %", angle: -90, position: "insideLeft" }} />
                                                <Tooltip content={(props) => <AnalyticsTooltip {...props} chartType="battery" showStopInfo={true} />} />
                                                <Legend />
                                                {chartData.map((entry, index) => {
                                                    if (entry.stopRatio > 0 && index < chartData.length - 1) {
                                                        return (
                                                            <ReferenceArea 
                                                                key={`stop-${index}`}
                                                                x1={entry.timeOfDay} 
                                                                x2={chartData[index + 1].timeOfDay}
                                                                fill="#6b6b69"
                                                                fillOpacity={entry.stopRatio * 0.12}
                                                            />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Line type="linear" dataKey="socPercentage" name="State of Charge (%)" stroke="#82ca9d" dot={false} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                                
                                <div className="chart-section">
                                    <h3>Electricity Rate</h3>
                                    <div style={{ position: 'relative', width: '100%', height: 200 }}>
                                        <ResponsiveContainer width="100%" height={200}>
                                            <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis 
                                                    dataKey="timeOfDay" 
                                                    type="category" 
                                                    allowDuplicatedCategory={false} 
                                                />
                                                <YAxis label={{ value: "$/kWh", angle: -90, position: "insideLeft" }} />
                                                <Tooltip content={(props) => <AnalyticsTooltip {...props} chartType="rate" showStopInfo={true} />} />
                                                <Legend />
                                                {chartData.map((entry, index) => {
                                                    if (entry.stopRatio > 0 && index < chartData.length - 1) {
                                                        return (
                                                            <ReferenceArea 
                                                                key={`stop-${index}`}
                                                                x1={entry.timeOfDay} 
                                                                x2={chartData[index + 1].timeOfDay}
                                                                fill="#6b6b69"
                                                                fillOpacity={entry.stopRatio * 0.12}
                                                            />
                                                        );
                                                    }
                                                    return null;
                                                })}
                                                <Line type="stepAfter" dataKey="electricityRate" name="Electricity Rate ($/kWh)" stroke="#8884d8" dot={false} />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <p>No operational data available for this route.</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};