import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './css/LoginLandingPage.css';
import { HomeButton } from '../components/website/HomeButton';
import { API_ENDPOINTS } from '../config/apiConfig';

function ResetPassword() {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { token } = useParams();
    console.log(token);

    const handleResetPassword = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        console.log(password);

        axios.post(API_ENDPOINTS.resetPassword, {
            old_password: password,
            new_password: confirmPassword,
            token: token
        })
        .then(response => {
            console.log(response);
        })
    }

    return (
        <div className='login-landing-container'>
            {/* <script src="https://apis.google.com/js/platform.js" async defer></script> */}
            <div className='login-background-half'>
                <div className='login-image-overlay'>
                    <p>Empower your fleet</p>
                </div>
            </div>
            <div className='login-form-half'>
                <div className='login-home-button-container'>
                    <HomeButton />
                </div>
                <div className='login-form-container'>
                    <div className='login-form-group'>
                        <label htmlFor='password'>New Password</label>
                        <input type='password' className='login-input' id='password' onChange={(e) => setPassword(e.target.value)} required/>
                    </div>
                    <div className='login-form-group'>
                        <label htmlFor='confirm-password'>Confirm Password</label>
                        <input type='password' className='login-input' id='confirm-password' onChange={(e) => setConfirmPassword(e.target.value)} required/>
                    </div>
                    <div className='login-button-container'>
                        <button type='submit' className='login-button' onClick={handleResetPassword}>Reset Password</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;