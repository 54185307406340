import React from "react";
import './css/AnalyticsBox.css';

export const AnalyticsBox = ({
    title,
    value
}) => {

    return (
        <div className="analytics-box-container">
            <p className="analytics-title">{ title }</p>
            <p className="analytics-value">${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        </div>
    );
};