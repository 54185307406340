import "../../App.css";
import './css/parameters.css';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { LoadingSpinner } from "../../components/general/LoadingSpinner";
import { API_ENDPOINTS } from '../../config/apiConfig';

// Import step components
import RouteSelection from "../../components/pro_calculator/parameters/RouteSelection";
import DepotSelection from "../../components/pro_calculator/parameters/DepotSelection";
import TruckSelection from "../../components/pro_calculator/parameters/TruckSelection";
import TOUSettings from "../../components/pro_calculator/parameters/TOUSettings";
import OtherParameters from "../../components/pro_calculator/parameters/OtherParameters";
import ReviewAndOptimize from "../../components/pro_calculator/parameters/ReviewAndOptimize";
import { expandEnergyChargeData } from "../../components/pro_calculator/parameters/TOUSettings";
import { parameterGroups, initializeDefaultParameters } from "../../components/pro_calculator/parameters/OtherParameters";
import ChargingStationSelection from "../../components/pro_calculator/parameters/ChargingStationSelection";

function Parameters() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [selectedDepots, setSelectedDepots] = useState([]);
  const [selectedChargingStations, setSelectedChargingStations] = useState([]);
  const [selectedTrucks, setSelectedTrucks] = useState([]);
  const [truckCustomData, setTruckCustomData] = useState({});
  const [energyChargeData, setEnergyChargeData] = useState([]);
  const [otherParameters, setOtherParameters] = useState(initializeDefaultParameters(parameterGroups));
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [optimizationResults, setOptimizationResults] = useState(null);

  const [routes, setRoutes] = useState([]);
  const [depots, setDepots] = useState([]);
  const [chargingStations, setChargingStations] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [loadingRoutes, setLoadingRoutes] = useState(true);
  const [loadingDepots, setLoadingDepots] = useState(true);
  const [loadingChargingStations, setLoadingChargingStations] = useState(true);
  const [loadingTrucks, setLoadingTrucks] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  // Step wizard state
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 7;


  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  // Authentication check
  useEffect(() => {
    const source = axios.CancelToken.source();

    axios
      .get(API_ENDPOINTS.user, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          setAuthenticated(false);
          navigate("/login");
        }
      });
    return () => {
      source.cancel("Component unmounted, request canceled");
    };
  }, [navigate, authToken]);

  // Handle loading overlay class
  useEffect(() => {
    if (loadingOverlay) {
      document.body.classList.add('loading-active');
    } else {
      document.body.classList.remove('loading-active');
    }

    return () => {
      document.body.classList.remove('loading-active');
    };
  }, [loadingOverlay]);

  // Fetch routes data
  useEffect(() => {
    if (authToken) {
      setLoadingRoutes(true);
      axios.get(API_ENDPOINTS.getAllRawRoutes, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
        .then((response) => {
          const fetchedRoutes = response.data;
          setRoutes(fetchedRoutes);
          // Select all routes by default
          setSelectedRoutes(fetchedRoutes.map(route => route.id));
          setLoadingRoutes(false);
        })
        .catch((error) => {
          console.error("Error fetching routes:", error);
          setLoadingRoutes(false);
        });
    }
  }, []);

  // Fetch depots data
  useEffect(() => {
    if (authToken) {
      setLoadingDepots(true);
      axios.get(API_ENDPOINTS.getDepot, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
        .then((response) => {
          const fetchedDepots = response.data;
          setDepots(fetchedDepots);
          // Select all depots by default
          setSelectedDepots(fetchedDepots.map(depot => depot.depot_id));
          setLoadingDepots(false);
        })
        .catch((error) => {
          console.error("Error fetching depots:", error);
          setFetchError("Failed to load depots. Please try again.");
          setLoadingDepots(false);
        });
    }
  }, []);

  // Fetch trucks data
  useEffect(() => {
    setLoadingTrucks(true);
    axios.get(API_ENDPOINTS.getTruckData)
      .then((response) => {
        const fetchedTrucks = response.data;
        setTrucks(fetchedTrucks);
        // Select all trucks by default
        setSelectedTrucks(fetchedTrucks.map(truck => truck.truck_id));
        setLoadingTrucks(false);
      })
      .catch((error) => {
        console.error("Error fetching trucks:", error);
        setLoadingTrucks(false);
      });
  }, []);

  // Fetch charging stations data
  useEffect(() => {
    if (authToken) {
      setLoadingChargingStations(true);
      axios.get(API_ENDPOINTS.getCharger, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
        .then((response) => {
          const fetchedStations = response.data;
          setChargingStations(fetchedStations);
          // Select all charging stations by default
          setSelectedChargingStations(fetchedStations.map(station => station.cs_id));
          setLoadingChargingStations(false);
        })
        .catch((error) => {
          console.error("Error fetching charging stations:", error);
          setLoadingChargingStations(false);
        });
    }
  }, []);

  // if (!userData) {
  //   return <p>Loading ... </p>;
  // }

  // Step navigation
  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // Render the current step
  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <RouteSelection
            routes={routes}
            loading={loadingRoutes}
            selectedRoutes={selectedRoutes}
            setSelectedRoutes={setSelectedRoutes}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <DepotSelection
            depots={depots}
            loading={loadingDepots}
            error={fetchError}
            selectedDepots={selectedDepots}
            setSelectedDepots={setSelectedDepots}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <ChargingStationSelection
            chargingStations={chargingStations}
            loading={loadingChargingStations}
            error={fetchError}
            selectedChargingStations={selectedChargingStations}
            setSelectedChargingStations={setSelectedChargingStations}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 4:
        return (
          <TruckSelection
            trucks={trucks}
            loading={loadingTrucks}
            selectedTrucks={selectedTrucks}
            setSelectedTrucks={setSelectedTrucks}
            truckCustomData={truckCustomData}
            setTruckCustomData={setTruckCustomData}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 5:
        return (
          <TOUSettings
            energyChargeData={energyChargeData}
            setEnergyChargeData={setEnergyChargeData}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 6:
        return (
          <OtherParameters
            otherParameters={otherParameters}
            setOtherParameters={setOtherParameters}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 7:
        return (
          <ReviewAndOptimize
            selectedRoutes={selectedRoutes}
            selectedDepots={selectedDepots}
            selectedChargingStations={selectedChargingStations}
            selectedTrucks={selectedTrucks}
            truckCustomData={truckCustomData}
            routes={routes}
            depots={depots}
            chargingStations={chargingStations}
            trucks={trucks}
            energyChargeData={energyChargeData}
            otherParameters={otherParameters}
            setLoadingOverlay={setLoadingOverlay}
            prevStep={prevStep}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <div className="dashboard-container">
          <div className="step-progress">
            {[1, 2, 3, 4, 5, 6, 7].map(step => (
              <div
                key={step}
                className={`step-indicator ${currentStep === step ? 'active' : ''} ${currentStep > step ? 'completed' : ''}`}
                onClick={() => step < currentStep && setCurrentStep(step)}
              >
                <div className="step-number">{step}</div>
                <div className="step-label">
                  {step === 1 && "Routes"}
                  {step === 2 && "Depots"}
                  {step === 3 && "Public Chargers"}
                  {step === 4 && "Vehicle Models"}
                  {step === 5 && "Energy Rates"}
                  {step === 6 && "Parameters"}
                  {step === 7 && "Review"}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="wizard-container">
          {renderCurrentStep()}
        </div>

        {loadingOverlay && (
          <LoadingSpinner message="Running optimization. This may take a few moments..." />
        )}
      </div>
    </div>
  );
}

export default Parameters;
