import React from "react";
import SelectionTable from "./common/SelectionTable";

const DepotSelection = ({ depots, loading, error, selectedDepots, setSelectedDepots, nextStep, prevStep }) => {
  // Handle checkbox change
  const handleCheckboxChange = (depotIdOrArray, e) => {
    // Stop propagation if event is provided
    if (e) {
      e.stopPropagation();
    }
    
    setSelectedDepots((prevSelected) => {
      // Handle array of IDs (for select all/none functionality)
      if (Array.isArray(depotIdOrArray)) {
        return depotIdOrArray;
      }
      // Handle single ID toggle
      const depotId = depotIdOrArray;
      if (prevSelected.includes(depotId)) {
        return prevSelected.filter((id) => id !== depotId);
      } else {
        return [...prevSelected, depotId];
      }
    });
  };

  // Handle "Select All" checkbox
  const handleSelectAllDepots = (e) => {
    if (e) e.stopPropagation();
    if (allDepotsSelected) {
      // Deselect all depots
      handleCheckboxChange([]);
    } else {
      // Select all depots at once
      handleCheckboxChange(depots.map(depot => depot.depot_id));
    }
  };

  const allDepotsSelected = depots.length > 0 && selectedDepots.length === depots.length;

  // Define columns for the depot table
  const depotColumns = [
    { key: 'name', label: 'Depot Name', width: '20%' },
    { key: 'address', label: 'Address', width: '25%' },
    { key: 'utility', label: 'Electric Utility', width: '15%' },
    { key: 'circuit', label: 'Nearby Power Circuits (Distance)', width: '35%' }
  ];

  // Define how to render each row
  const renderDepotRow = (depot) => (
    <>
      <td style={{ width: depotColumns[0].width }}>{depot.name || "—"}</td>
      <td style={{ width: depotColumns[1].width }}>{depot.address || `${depot.lat}, ${depot.lon}`}</td>
      <td style={{ width: depotColumns[2].width }}>{depot.utility || "SCE"} </td>
      <td style={{ width: depotColumns[3].width }}>
        {depot.circuits && depot.circuits.length > 0 && depot.circuit_powers && depot.circuit_dists ? (
          depot.circuits.map((circuit, idx) => (
            depot.circuit_powers[idx] !== undefined && depot.circuit_dists[idx] !== undefined ? (
              <span key={idx}>
                <span style={{ fontWeight: 300, color: '#6366f1' }}>Circuit -</span> {circuit}: {(depot.circuit_powers[idx]/1000).toFixed(2)} MW ({depot.circuit_dists[idx].toFixed(1)} miles)
                {idx < depot.circuits.length - 1 ? <br/> : null}
              </span>
            ) : null
          ))
        ) : (
          <span>No circuit information available</span>
        )}
      </td>
    </>
  );

  if (loading) {
    return <div className="step-container"><p>Loading depots...</p></div>;
  }

  if (error) {
    return <div className="step-container"><p className="error">{error}</p></div>;
  }

  return (
    <div className="step-container">
      <h2>Step 2: Depot Selection</h2>
      <p>Select the depots you want to include in your optimization. These are the locations you can install your own chargers.</p>

      {depots.length === 0 ? (
        <p>No depots found. Please add depots to continue.</p>
      ) : (
        <SelectionTable
          items={depots}
          selectedItems={selectedDepots}
          handleCheckboxChange={handleCheckboxChange}
          handleSelectAll={handleSelectAllDepots}
          allSelected={allDepotsSelected}
          columns={depotColumns}
          itemIdKey="depot_id"
          renderRow={renderDepotRow}
        />
      )}

      <div className="step-actions">
        <button className="back-btn" onClick={prevStep}>
          Back
        </button>
        <button 
          className="next-btn" 
          onClick={nextStep} 
          //disabled={selectedDepots.length === 0}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default DepotSelection; 