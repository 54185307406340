import React, { useState, useEffect, useRef } from "react";
import "./css/DepotTable.css";
import "./css/TableCommon.css";
import axios from "axios";
import { API_ENDPOINTS } from "../../../config/apiConfig";

export const DepotTable = ({
  selectedDepots,
  onDepotSelect,
  lastMapSelection,
  tableData: propTableData = [],
  selectTable,
  handleSelectTable
}) => {
  const [tableData, setTableData] = useState(propTableData);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const rowRefs = useRef({});
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    console.log("DepotTable received propTableData:", propTableData);
    setTableData(propTableData);
  }, [propTableData]);

  useEffect(() => {
    if (lastMapSelection && rowRefs.current[lastMapSelection]) {
      rowRefs.current[lastMapSelection].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setHighlightedRow(lastMapSelection);
      
      const timer = setTimeout(() => {
        setHighlightedRow(null);
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [lastMapSelection]);

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    
    const updatedSelection = {};
    
    if (newSelectAll) {
      tableData.forEach(depot => {
        if (depot.depot_id) {
          updatedSelection[depot.depot_id] = true;
        }
      });
    }
    
    onDepotSelect(updatedSelection, true);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      const allSelected = tableData.every(depot => 
        depot.depot_id && selectedDepots[depot.depot_id]
      );
      setSelectAll(allSelected);
    }
  }, [selectedDepots, tableData]);

  if (!tableData || tableData.length === 0) {
    return (
      <>
        <h3 className="depot-header">Depots</h3>
        <div className="no-data-message">
          No depots available. Add a depot to get started.
        </div>
      </>
    );
  }

  return (
    <>
      <div className="table-header-container">
        <div className="table-toggle-buttons">
          <button 
            className={`table-select-button ${selectTable === "route" ? "selected" : ""}`} 
            value="route" 
            onClick={(e) => {
              e.preventDefault();
              handleSelectTable(e);
            }}
            type="button"
          >
            <span className={`table-select-button-text ${selectTable === "route" ? "selected-text" : ""}`}>
              Routes
            </span>
          </button>
          <button 
            className={`table-select-button ${selectTable === "depot" ? "selected" : ""}`} 
            value="depot" 
            onClick={(e) => {
              e.preventDefault();
              handleSelectTable(e);
            }}
            type="button"
          >
            <span className={`table-select-button-text ${selectTable === "depot" ? "selected-text" : ""}`}>
              Depots
            </span>
          </button>
          <button 
            className={`table-select-button ${selectTable === "charger" ? "selected" : ""}`} 
            value="charger" 
            onClick={(e) => {
              e.preventDefault();
              handleSelectTable(e);
            }}
            type="button"
          >
            <span className={`table-select-button-text ${selectTable === "charger" ? "selected-text" : ""}`}>
              Public Chargers
            </span>
          </button>
        </div>
      </div>
      <div className="table-wrapper">
        <table className="table">
          <thead bgcolor="#5E5ADE">
            <tr className="table-header">
              <th className="table-header-text" style={{ width: '4%' }}>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  className="depot-checkbox select-all-checkbox"
                />
              </th>
              <th className="table-header-text" style={{ width: '15%' }}>Name</th>
              <th className="table-header-text" style={{ width: '20%' }}>Address</th>
              {/* <th className="table-header-text" style={{ width: '16%' }}>Coordinates</th> */}
              <th className="table-header-text" style={{ width: '15%' }}>Electric Utility</th>
              <th className="table-header-text" style={{ width: '30%' }}>Nearby Power Circuits (Distance)</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((depot, index) => {
              console.log(`Rendering depot ${index}:`, depot);
              return (
                <tr
                  ref={(el) => (rowRefs.current[depot.depot_id] = el)}
                  key={`${depot.depot_id}-${index}`}
                  className={`table-content ${
                    selectedDepots[depot.depot_id] ? "selected" : ""
                  } ${highlightedRow === depot.depot_id ? "highlighted" : ""}`}
                >
                  <td style={{ width: '4%' }}>
                    <input
                      type="checkbox"
                      checked={selectedDepots[depot.depot_id] || false}
                      onChange={() => onDepotSelect(depot.depot_id)}
                      className="depot-checkbox"
                    />
                  </td>
                  <td style={{ width: '15%' }}>{depot.name}</td>
                  <td style={{ width: '20%' }}>{depot.address}</td>
                  {/* <td style={{ width: '16%' }}>{`${depot.lat}, ${depot.lon}`}</td> */}
                  <td style={{ width: '15%' }}>
                    {depot.utility ? `${depot.utility}${depot.power_capacity ? ` (${depot.power_capacity}kW)` : ''}` : 'SCE'}
                  </td>
                  <td style={{ width: '30%' }}>
                    {depot.circuits && depot.circuits.length > 0 && depot.circuit_powers && depot.circuit_dists ? (
                      depot.circuits.map((circuit, idx) => (
                        depot.circuit_powers[idx] !== undefined && depot.circuit_dists[idx] !== undefined ? (
                          <span key={idx}>
                            <span style={{ fontWeight: 300, color: '#6366f1' }}>Circuit -</span> {circuit}: {(depot.circuit_powers[idx]/1000).toFixed(2)} MW ({depot.circuit_dists[idx].toFixed(1)} miles)
                            {idx < depot.circuits.length - 1 ? <br/> : null}
                          </span>
                        ) : null
                      ))
                    ) : (
                      <span>No circuit information available</span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
