import React from "react";
import SelectionTable from "./common/SelectionTable";

const ChargingStationSelection = ({ 
  chargingStations, 
  loading, 
  error, 
  selectedChargingStations, 
  setSelectedChargingStations, 
  nextStep, 
  prevStep 
}) => {
  
  // Handle checkbox change
  const handleCheckboxChange = (stationIdOrArray, e) => {
    // Stop propagation if event is provided
    if (e) {
      e.stopPropagation();
    }
    
    setSelectedChargingStations((prevSelected) => {
      // Handle array of IDs (for select all/none functionality)
      if (Array.isArray(stationIdOrArray)) {
        return stationIdOrArray;
      }
      // Handle single ID toggle
      const stationId = stationIdOrArray;
      if (prevSelected.includes(stationId)) {
        return prevSelected.filter((id) => id !== stationId);
      } else {
        return [...prevSelected, stationId];
      }
    });
  };

  // Handle "Select All" checkbox
  const handleSelectAllStations = (e) => {
    if (e) e.stopPropagation();
    if (allStationsSelected) {
      // Deselect all stations
      handleCheckboxChange([]);
    } else {
      // Select all stations at once
      handleCheckboxChange(chargingStations.map(station => station.cs_id));
    }
  };

  const allStationsSelected = chargingStations.length > 0 && 
    selectedChargingStations.length === chargingStations.length;

  // Define columns for the charging station table
  const stationColumns = [
    { key: 'name', label: 'Name', width: '20%' },
    { key: 'owner', label: 'Owner', width: '15%' },
    { key: 'addr', label: 'Address', width: '25%' },
    { key: 'status', label: 'Status', width: '10%' },
    { key: 'max_power', label: 'Max Power', width: '10%' },
    { key: 'connectors', label: 'Connector Types', width: '15%' }
  ];

  // Define how to render each row
  /**
   * TODO: Temporary workaround for table header/content misalignment
   * 
   * When the scrollbar appears for charging stations, it pushes the table body left,
   * causing the headers to become misaligned with the content. Currently using extra
   * spacing in content cells as a temporary fix, but this needs a proper solution.
   * 
   */
  const renderStationRow = (station) => (
    <>
      <td style={{ width: stationColumns[0].width }}>{station.name || "—"}</td>
      <td style={{ width: stationColumns[1].width }}>{"\u00A0" + station.owner || "—"}</td>
      <td style={{ width: stationColumns[2].width }}>{"\u00A0" + station.addr || `${station.lat}, ${station.lon}`}</td>
      <td style={{ width: stationColumns[3].width }}>{"\u00A0\u00A0" + station.status || "—"}</td>
      <td style={{ width: stationColumns[4].width }}>{"\u00A0\u00A0" + station.max_power} kW</td>
      <td style={{ width: stationColumns[5].width }}>{"\u00A0\u00A0\u00A0" + (station.accepted_connector_types || "—")}</td>
    </>
  );

  if (loading) {
    return <div className="step-container"><p>Loading charging stations...</p></div>;
  }

  if (error) {
    return <div className="step-container"><p className="error">{error}</p></div>;
  }

  return (
    <div className="step-container">
      <h2>Step 3: Public Charger Selection</h2>
      <p>Select the public charging stations you want to include in your optimization. These are the third-party commercial charging stations.</p>

      {chargingStations.length === 0 ? (
        <p>No charging stations found. Please add charging stations to continue.</p>
      ) : (
        <SelectionTable
          items={chargingStations}
          selectedItems={selectedChargingStations}
          handleCheckboxChange={handleCheckboxChange}
          handleSelectAll={handleSelectAllStations}
          allSelected={allStationsSelected}
          columns={stationColumns}
          itemIdKey="cs_id"
          renderRow={renderStationRow}
        />
      )}

      <div className="step-actions">
        <button className="back-btn" onClick={prevStep}>
          Back
        </button>
        <button 
          className="next-btn" 
          onClick={nextStep}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ChargingStationSelection; 