import React, { useState } from 'react';
import "../../App.css";
import Popup from "reactjs-popup";
import "./addButton.css";
import { GoPlus } from "react-icons/go";
import { CgAdd } from "react-icons/cg";
import { FiMinusCircle } from "react-icons/fi";
import AddressInput from './addressInput';

export const AddButton = ({ entity, position, fields, onSubmit, dynamicAddRoute = false, text }) => {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (fieldId, value) => {
    setFormData(prev => ({
      ...prev,
      [fieldId]: value
    }));
    // Clear error when user types
    if (errors[fieldId]) {
      setErrors(prev => ({
        ...prev,
        [fieldId]: null
      }));
    }
  };

  const addStop = () => {
    setFormData((prev) => ({
      ...prev,
      stops: [...(prev.stops || []), ""],
    }));
  };

  const removeStop = (index) => {
    setFormData((prev) => ({
      ...prev,
      stops: prev.stops.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    fields.forEach(field => {
      if (!formData[field.id]) {
        newErrors[field.id] = `${field.name} is required`;
      }
      if (field.type === 'number' && isNaN(parseFloat(formData[field.id]))) {
        newErrors[field.id] = `${field.name} must be a number`;
      }
    });
    
    // Validate stops and idle times if dynamicAddRoute is enabled
    if (dynamicAddRoute && formData.stops && formData.stops.length > 0) {
      // Check if any stop address is empty
      formData.stops.forEach((stop, index) => {
        if (!stop) {
          newErrors[index] = `Stop ${index + 1} address is required`;
        }
        
        // Check idle times for all stops except the last one
        if (index < formData.stops.length - 1) {
          if (!formData.idle_times || !formData.idle_times[index]) {
            newErrors[`idle_time_${index}`] = `Idle time for Stop ${index + 1} is required`;
          }
        }
      });
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (close) => {
    if (validateForm()) {
      try {
        setIsLoading(true); // Start loading
        await onSubmit(formData);
        setFormData({}); // Clear form after successful submission
        setIsLoading(false); // Stop loading
        close();
      } catch (error) {
        console.error('Error submitting form:', error);
        setIsLoading(false); // Stop loading on error
        // You could set a general error state here if needed
      }
    }
  };

  const renderInput = (field) => {
    if (field.type === 'address') {
      return (
        <div>
          <AddressInput
            onAddressSelect={(address) => handleInputChange(field.id, address)}
          />
          {errors[field.id] && <div className="error-message">{errors[field.id]}</div>}
        </div>
      );
    }
    
    if (field.type === 'time') {
      return (
        <div>
          <input
            type="time"
            id={field.id}
            className="popup-input time-input"
            value={formData[field.id] || ''}
            onChange={(e) => handleInputChange(field.id, e.target.value)}
          />
          <small className="input-helper-text">Enter departure time (12-hour format)</small>
          {errors[field.id] && <div className="error-message">{errors[field.id]}</div>}
        </div>
      );
    }

    return (
      <div>
        <input
          type={field.type}
          id={field.id}
          className="popup-input"
          value={formData[field.id] || ''}
          onChange={(e) => handleInputChange(field.id, e.target.value)}
        />
        {errors[field.id] && <div className="error-message">{errors[field.id]}</div>}
      </div>
    );
  };

  return (
    <div className="btn-container">
      <Popup trigger={
        <button className={`add-button-custom ${position}`}>
          <GoPlus size={19} /> {text || `Add a ${entity}`}
        </button>
      } modal nested>
        {close => (
          <div className="popup-container">
            {isLoading && (
              <div className="loading-overlay-form">
                <div className="loading-spinner-form"></div>
              </div>
            )}
            <div className="close-container">
              <button className="close" onClick={() => close()}>&times;</button>
            </div>
            <div className="popup-content">
              <h3 className="popup-title">Add a {entity}</h3>
              <div className="popup-input-group-container">
                {fields.map((field) => (
                  <div className="popup-input-group" key={field.id}>
                    <div className="popup-input-group-label">
                      {field.name}
                    </div>
                    {renderInput(field)}
                  </div>
                ))}

                {dynamicAddRoute && (
                  <div className="dynamic-route-section">
                    <div className="dynamic-route-section-title">Stops</div>
                    {formData.stops?.map((stop, index) => (
                      <div key={index} className='stop-row'>
                        <p className='popup-input-group-label'>Stop {index + 1}</p>
                        <AddressInput
                          onAddressSelect={(address) => {
                            const updatedStops = [...(formData.stops || [])];
                            updatedStops[index] = address;
                            setFormData((prev) => ({ ...prev, stops: updatedStops }));
                          }}
                        />
                        {errors[index] && <div className="error-message">{errors[index]}</div>}
                        {/* Only show idle time input if this is not the last stop */}
                        {index < formData.stops.length - 1 && (
                          <div>
                            <input
                              className='popup-input-idle-time'
                              type="number"
                              placeholder='Idle time (minutes) *'
                              value={formData.idle_times?.[index] || ''}
                              onChange={(e) => {
                                const updatedTimes = [...(formData.idle_times || [])];
                                updatedTimes[index] = e.target.value;
                                setFormData((prev) => ({ ...prev, idle_times: updatedTimes }));
                              }}
                            />
                            <small className="input-helper-text">Time spent at this stop before continuing</small>
                            {errors[`idle_time_${index}`] && <div className="error-message">{errors[`idle_time_${index}`]}</div>}
                          </div>
                        )}
                        <button 
                          className="remove-route-btn" 
                          type="button" 
                          onClick={() => removeStop(index)}
                          aria-label={`Remove stop ${index + 1}`}
                        >
                          <FiMinusCircle size={23} />
                        </button>
                      </div>
                    ))}
                    
                    {(!formData.stops || formData.stops.length <= 6) && (
                      <button 
                        className="add-route-btn" 
                        type="button" 
                        onClick={addStop}
                      >
                        <CgAdd size={20} /> Add a stop
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="add-button-container">
              <button 
                className="add-button-popup" 
                onClick={() => handleSubmit(close)}
                disabled={isLoading}
              >
                {isLoading ? 'Adding...' : (
                  <>
                    <GoPlus size={19} /> Add {entity}
                  </>
                )}
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};