import React, { useMemo, useEffect } from "react";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { AnalyticsButton } from "./AnalyticsButton";
import calculatorParameters from "../../../data/calculator_parameters.json";

export const AnalyticsHighlights = ({
  optimizationResults,
  selectedRecommendedTruck,
  setSelectedRecommendedTruck,
}) => {
  // Moved getTruckInfo function here
  const getTruckInfo = useMemo(() => {
    const truckInfo = {};
    
    // Map through the ElectricTruckModelInfo data
    calculatorParameters.ElectricTruckModelInfo.forEach(truck => {
      // Create the truck object with mapped properties - directly using all relevant data from JSON
      truckInfo[truck.id] = {
        id: truck.id,
        name: truck.title,
        image: truck.imgUrl.startsWith('/') ? `${window.location.origin}${truck.imgUrl}` : truck.imgUrl,
        link: truck.oemLink,
        price: truck.msrp,
        batteryCapacity: truck.batteryCap,
        range: truck.operationRange
      };
    });
    
    return truckInfo;
  }, []);

  // Moved getRecommendedTrucks function here
  const recommendedTruckModels = useMemo(() => {
    const recommendedTruckModels = {};

    // Get the routes object from the optimization results
    const routes = optimizationResults?.data?.operation?.routes;
    if (!routes) return {};

    // Iterate through each route
    Object.values(routes).forEach(route => {
      // Check if the route has a selected model and it's electric
      if (route.selected_model_id && route.is_electric === true) {
        // Add the selected model to the array if it's not already included
        if (!recommendedTruckModels[route.selected_model_id]) {
          recommendedTruckModels[route.selected_model_id] = [
            route.route_name,
          ];
        }
        else {
          recommendedTruckModels[route.selected_model_id].push(route.route_name);
        }
      }
    });

    return recommendedTruckModels;
  }, [optimizationResults]);

  // Get truck info directly
  const truckInfo = getTruckInfo;
  const selectedRecommendedTruckInfo = selectedRecommendedTruck ? truckInfo[selectedRecommendedTruck] : null;

  // Add useEffect to select the first truck by default when data loads or changes
  useEffect(() => {
    if (optimizationResults && Object.keys(recommendedTruckModels).length > 0) {
      // If current selection is no longer valid, or nothing is selected, select the first truck
      if (!selectedRecommendedTruck || !recommendedTruckModels[selectedRecommendedTruck]) {
        const firstTruckId = Object.keys(recommendedTruckModels)[0];
        setSelectedRecommendedTruck(firstTruckId);
      }
    }
  }, [optimizationResults, recommendedTruckModels, selectedRecommendedTruck, setSelectedRecommendedTruck]);

  return (
    <>
      <div className="container-flex-row">
        <div className="dashboard-container summary-results">
          <p>Over a <span style={{color: "#4A90E2"}}><b>10</b></span> year period, you can save up to</p>
          <p className="summary-results-big-number">
            ${optimizationResults.data.finance.tco_analysis.financial_metrics.total_cost_of_ownership.savings.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}
          </p>
          <p>with our recommendations.</p>
        </div>
        <div className="dashboard-container summary-results">
          <p>Your cost per mile will decrease by</p>
          <p className="summary-results-big-number">
            ${optimizationResults.data.finance.tco_analysis.financial_metrics.tco_per_mile.savings.toLocaleString(undefined, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3
            })}
          </p>
          <p>from <span style={{color: "#E74C3C"}}>${optimizationResults.data.finance.tco_analysis.financial_metrics.tco_per_mile.pure_diesel_fleet.toLocaleString(undefined, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3
            })}</span> to <span style={{color: "#2ECC71"}}>${optimizationResults.data.finance.tco_analysis.financial_metrics.tco_per_mile.optimized_fleet.toLocaleString(undefined, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3
            })}</span> per mile.</p>
        </div>
      </div>

      <div className="container-flex-row infrastructure-recommendations">
        <div className="dashboard-container summary-results">
          {(() => {
            const totalRoutes = Object.keys(optimizationResults.data.operation.routes).length;
            const electricRoutes = Object.values(optimizationResults.data.operation.routes)
              .filter(route => route.is_electric === true).length;
            return (
              <>
                <p>Out of your <span style={{color: "#4A90E2"}}><b>{totalRoutes}</b></span> {totalRoutes > 1 ? "routes" : "route"},
                  we recommend</p>
                <p className="summary-results-big-number">{electricRoutes}</p>
                <p>{electricRoutes === 1 ? "route" : "routes"} to be electrified.</p>
              </>
            );
          })()}
        </div>
        <div className="dashboard-container summary-results">
          <p>In your <span style={{color: "#4A90E2"}}><b>{Object.keys(optimizationResults.data.operation.depots).length}</b></span> {Object.keys(optimizationResults.data.operation.depots).length > 1 ? "depots" : "depot"},
            we recommend </p>
          <p className="summary-results-big-number">{Object.values(optimizationResults.data.operation.depots).reduce((total, depot) => {
            return total + Object.values(depot.chargers).reduce((sum, num) => sum + num, 0); 
          }, 0)} </p>
          <p>{Object.values(optimizationResults.data.operation.depots).reduce((total, depot) => {
            return total + Object.values(depot.chargers).reduce((sum, num) => sum + num, 0); 
          }, 0) === 1 ? "charger" : "chargers"} for your fleet.</p>
        </div>
        <div className="dashboard-container summary-results">
          {/* <p>Read more about charging stations information <a href="https://forummobility.com/" target="_blank" rel="noopener noreferrer">here</a>.</p> */}
          {/* <p>See more details about your recommendations <a href="#overview">here</a>.</p> */}
          <p>See more details below.</p>
        </div>
      </div>

      <div className="container-flex-row">
        <div className="dashboard-container truck-recommendations">
          <div className="truck-recommendations-buttons">
            <p>Recommended truck(s) for your fleet:</p>
            <div className="truck-recommendations-button-container">
              {Object.keys(recommendedTruckModels).map((selected_model_id) => {
                // Find the truck info from calculatorParameters to get the title
                const truckInfo = calculatorParameters.ElectricTruckModelInfo.find(
                  truck => truck.id === parseInt(selected_model_id)
                );
                const numRoutes = recommendedTruckModels[selected_model_id].length;
                return (
                  <AnalyticsButton
                    key={selected_model_id}
                    label={`${truckInfo ? truckInfo.title : `Model ${selected_model_id}`} x ${numRoutes}`}
                    handler={() => setSelectedRecommendedTruck(selected_model_id)}
                    isSelected={selectedRecommendedTruck === selected_model_id}
                  />
                );
              })}
            </div>
          </div>
          <div className="truck-recommendations-info">
            {
              selectedRecommendedTruckInfo && (
                <div className="truck-recommendations-info-container">
                  <p className="truck-recommendations-info-title">{selectedRecommendedTruckInfo.name}</p>
                  <div className="truck-image-container">
                    <a href={selectedRecommendedTruckInfo.link} target="_blank" rel="noopener noreferrer">
                      <img
                        className="truck-recommendations-info-image"
                        src={selectedRecommendedTruckInfo.image}
                        alt={selectedRecommendedTruckInfo.name}
                      />
                      <div className="link-icon-overlay">
                        <FaArrowUpRightFromSquare />
                      </div>
                    </a>
                  </div>
                  {/* <p>MSRP: ${selectedRecommendedTruckInfo.price.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}</p> */}
                  <p>Battery Capacity: {selectedRecommendedTruckInfo.batteryCapacity} kWh</p>
                  <p>Estimated Range: {selectedRecommendedTruckInfo.range} miles</p>
                </div>
              )
            }
          </div>

          <div className="truck-recommendations-info-routes">
            {selectedRecommendedTruck && (
              <>
                <p>Route(s) served by this truck model:</p>
                <ul>
                  {recommendedTruckModels[selectedRecommendedTruck]?.map(route => (
                    <li key={route}>{route}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
