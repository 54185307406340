import React, { useState } from "react";
import { ParameterInput } from "../parameterinput";

// Handling advanced parameters
// Daily costs are not included in the list, they are calculated under the hood
export const parameterGroups = [
  {
    category: "Diesel Truck Cost",
    inputs: [
      { label: "Diesel price", description: "Purchase price of diesel truck", id: "diesel_price", defaultValue: 150000 },
      { label: "Diesel life", description: "Expected operational life in years", id: "diesel_life", defaultValue: 12 },
    ],
  },
  {
    category: "Fuel and Energy",
    inputs: [
      { label: "Fuel cost", description: "Cost per gallon of diesel", id: "fuel_cost", defaultValue: 5.387 },
      { label: "Diesel MPG", description: "MPG (miles per gallon) of diesel trucks", id: "diesel_mpg", defaultValue: 6.5 },
      { label: "Demand charge", description: "Monthly power demand charge", id: "demand_charge", defaultValue: 0.0 },
      { label: "Public charging cost", description: "Additional cost per kWh at public stations", id: "pub_charge_cost", defaultValue: 0.2 },
    ],
  },
  {
    category: "Infrastructure",
    inputs: [
      { label: "Depot construction cost", description: "Depot charging construction cost", id: "depot_const_cost", defaultValue: 250000 },
      { label: "Depot life", description: "Expected life of charging infrastructure in years", id: "depot_life", defaultValue: 20 },
      { label: "Connection cost", description: "Cost per mile for circuit connection", id: "connect_cost", defaultValue: 150000 },
    ],
  },
  {
    category: "Penalties",
    inputs: [
      { label: "Non ZEV penalty", description: "Daily penalty for non-zero emission trucks", id: "non_ze_penalty", defaultValue: 20 },
    ],
  },
  {
    category: "Maintenance",
    inputs: [
      { label: "Diesel maintenance", description: "Cost per mile for maintenance (diesel)", id: "diesel_maint", defaultValue: 0.21 },
      { label: "Electric maintenance", description: "Cost per mile for maintenance (electric)", id: "elec_maint", defaultValue: 0.1 },
    ],
  },
  {
    category: "Insurance",
    inputs: [
      { label: "Diesel insurance cost", description: "Annual insurance cost (diesel)", id: "diesel_ins_yr", defaultValue: 15000 },
      { label: "Electric insurance cost", description: "Annual insurance cost (electric)", id: "elec_ins_yr", defaultValue: 15000 },
    ],
  },
];

// Initialize the parameters
export const initializeDefaultParameters = (groups) => {
  const defaults = {};
  groups.forEach((group) => {
    group.inputs.forEach((input) => {
      defaults[input.id] = input.defaultValue || "0"; // Use defaultValue if defined
    });
  });
  return defaults;
};

const OtherParameters = ({ 
  otherParameters,
  setOtherParameters, 
  nextStep, 
  prevStep 
}) => {
  // Manage expanded panels state internally
  const [expandedPanels, setExpandedPanels] = useState({});

  // Toggle panel expansion
  const togglePanel = (category) => {
    setExpandedPanels(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  // Handle parameter changes internally
  const handleInputChange = (id, value) => {
    setOtherParameters(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <div className="step-container">
      <h2>Step 6: Advanced Parameters</h2>
      <p>Adjust the parameters to match your fleet's specific requirements.</p>

      <div className="parameters-column">
        {parameterGroups.map((group) => (
          <div key={group.category} className="parameter-panel">
            <div
              className="panel-header"
              onClick={() => togglePanel(group.category)}
            >
              <h3>{group.category}</h3>
              <span className={`panel-toggle ${expandedPanels[group.category] ? 'expanded' : ''}`}>
                {expandedPanels[group.category] ? '−' : '+'}
              </span>
            </div>

            <div className={`panel-content ${expandedPanels[group.category] ? 'expanded' : ''}`}>
              {group.inputs.map((input) => (
                <ParameterInput
                  key={input.id}
                  label={input.label}
                  description={input.description}
                  id={input.id}
                  value={otherParameters[input.id] || ""}
                  onChange={(e) => handleInputChange(input.id, e.target.value)}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="step-actions">
        <button className="back-btn" onClick={prevStep}>
          Back
        </button>
        <button className="next-btn" onClick={nextStep}>
          Next
        </button>
      </div>
    </div>
  );
};

export default OtherParameters; 