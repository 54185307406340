import ReactGA from 'react-ga4';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Configure GA4 with debug mode or production mode
export const initGA = () => {
  ReactGA.initialize('G-DGQ14FN3XS', {
    // debug: process.env.NODE_ENV === 'development',
    // testMode: process.env.NODE_ENV === 'development',
    debug: false,
    testMode: false,
  });
};

// Debug logger function
export const debugLog = (message, data) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`🔍 ${message}`, data);
  }
};

// Separate the debugger into its own component with debug-only rendering
export const GADebugger = () => {
    const [events, setEvents] = useState([]);
    const [isMinimized, setIsMinimized] = useState(false);
  
    useEffect(() => {
      if (process.env.NODE_ENV === 'development') {
        const originalSend = ReactGA.send;
        ReactGA.send = (...args) => {
          setEvents(prev => [...prev, {
            timestamp: new Date().toISOString(),
            data: args[0]
          }]);
          originalSend.apply(ReactGA, args);
        };
  
        return () => {
          ReactGA.send = originalSend;
        };
      }
    }, []);
  
    // Only render in development mode
    // Disabled during demo video recording to avoid debugger showing up in videos
    if (process.env.NODE_ENV !== 'development' || true) {
      return null; 
    }
  
    return (
      <div style={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        background: '#f0f0f0',
        padding: 15,
        borderRadius: 5,
        maxHeight: isMinimized ? 40 : 300,
        overflowY: 'auto',
        zIndex: 9999,
        fontSize: 12
      }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: isMinimized ? 0 : 10 
        }}>
          <h4 style={{ margin: 0 }}>GA4 Debug Events</h4>
          <button 
            onClick={() => setIsMinimized(!isMinimized)}
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
              fontSize: '16px'
            }}
          >
            {isMinimized ? '🔽' : '🔼'}
          </button>
        </div>
        {!isMinimized && events.map((event, i) => (
          <div key={i} style={{ marginBottom: 10 }}>
            <div>{event.timestamp}</div>
            <pre>{JSON.stringify(event.data, null, 2)}</pre>
          </div>
        ))}
      </div>
    );
  };
  
// Enhanced page view tracking with debug logs
export const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: document.title
    });

    debugLog('GA4 Pageview:', {
      page: location.pathname,
      title: document.title,
      timestamp: new Date().toISOString()
    });
  }, [location]);

  return null;
};
