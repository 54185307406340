import React from "react";
import SelectionTable from "./common/SelectionTable";

const RouteSelection = ({ routes, loading, error, selectedRoutes, setSelectedRoutes, nextStep }) => {
  // Handle checkbox change
  const handleCheckboxChange = (routeIdOrArray, e) => {
    // Stop propagation if event is provided
    if (e) {
      e.stopPropagation();
    }
    
    setSelectedRoutes((prevSelected) => {
      // Handle array of IDs (for select all/none functionality)
      if (Array.isArray(routeIdOrArray)) {
        return routeIdOrArray;
      }
      // Handle single ID toggle
      const routeId = routeIdOrArray;
      if (prevSelected.includes(routeId)) {
        return prevSelected.filter((id) => id !== routeId);
      } else {
        return [...prevSelected, routeId];
      }
    });
  };

  // Handle "Select All" checkbox
  const handleSelectAllRoutes = (e) => {
    if (e) e.stopPropagation();
    if (allRoutesSelected) {
      // Deselect all routes
      handleCheckboxChange([]);
    } else {
      // Select all routes at once
      handleCheckboxChange(routes.map(route => route.id));
    }
  };

  const allRoutesSelected = routes.length > 0 && selectedRoutes.length === routes.length;

  // Define columns for the route table
  const routeColumns = [
    { key: 'raw_route_name', label: 'Route Name', width: '15%' },
    { key: 'origin', label: 'Origin', width: '25%' },
    { key: 'destination', label: 'Destination', width: '25%' },
    { key: 'distance', label: 'Travel Distance', width: '15%' },
    { key: 'start_time', label: 'Start Time', width: '15%' }
  ];

  // Use the column definitions in the render function
  const renderRouteRow = (route) => {
    const origin = route.addresses[0].toString();
    const destination = route.addresses[route.addresses.length - 1].toString();
    const totalDistance = parseFloat((route.distances.reduce((a, b) => a + b, 0) / 1609.34).toFixed(1));

    return (
      <>
        <td style={{ width: routeColumns[0].width }}>{route.raw_route_name}</td>
        <td style={{ width: routeColumns[1].width }}>{origin}</td>
        <td style={{ width: routeColumns[2].width }}>{destination}</td>
        <td style={{ width: routeColumns[3].width }}>{totalDistance} miles</td>
        <td style={{ width: routeColumns[4].width }}>{route.start_time}</td>
      </>
    );
  };

  if (loading) {
    return <div className="step-container"><p>Loading routes...</p></div>;
  }

  if (error) {
    return <div className="step-container"><p className="error">{error}</p></div>;
  }

  return (
    <div className="step-container">
      <h2>Step 1: Route Selection</h2>
      <p>Select the routes you want to include in your optimization. Each route will be served by one truck.</p>

      {routes.length === 0 ? (
        <p>No routes found. Please add routes to continue.</p>
      ) : (
        <SelectionTable
          items={routes}
          selectedItems={selectedRoutes}
          handleCheckboxChange={handleCheckboxChange}
          handleSelectAll={handleSelectAllRoutes}
          allSelected={allRoutesSelected}
          columns={routeColumns}
          itemIdKey="id"
          renderRow={renderRouteRow}
        />
      )}

      <div className="step-actions">
        <button 
          className="next-btn" 
          onClick={nextStep} 
          disabled={selectedRoutes.length === 0}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default RouteSelection; 