// Use environment variable in production, fallback to localhost in development; 
// Environment variables need to be prefixed with REACT_APP_
const API_BASE_URL = process.env.REACT_APP_ETRUCK_API_BASE_URL || 'http://localhost:8000';

// Use the following if development locally
// const API_BASE_URL = 'http://localhost:8000';

// Add debugging to see what's happening with the URL construction
// console.log('Environment variable:', process.env.ETRUCK_API_BASE_URL);
// console.log('API Base URL being used:', API_BASE_URL);
// console.log('Full login endpoint:', `${API_BASE_URL}/api/login/`);

export const API_ENDPOINTS = {
  // User management
  user: `${API_BASE_URL}/api/user/`,
  register: `${API_BASE_URL}/api/register/`,
  forgotPassword: `${API_BASE_URL}/api/forgotpwd/`,
  login: `${API_BASE_URL}/api/login/`,
  resetPassword: `${API_BASE_URL}/api/resetpwd/`,

  // Fleet planning
  getProcessedRoutes: `${API_BASE_URL}/api/getprocessedroutes/`,
  getRawRoute: `${API_BASE_URL}/api/getrawroute/`,
  getAllRawRoutes: `${API_BASE_URL}/api/getallrawroutes/`,
  addRoute: `${API_BASE_URL}/api/addroute/`,
  deleteRoute: `${API_BASE_URL}/api/deleteroutes/`,
  addDepot: `${API_BASE_URL}/api/adddepot/`,
  getDepot: `${API_BASE_URL}/api/getdepot/`,
  deleteDepot: `${API_BASE_URL}/api/deletedepots/`,
  getCircuits: `${API_BASE_URL}/api/getcircuits/`,
  getCharger: `${API_BASE_URL}/api/getcharger/`,
  getTruckData: `${API_BASE_URL}/api/gettruckdata/`,
  getAllFleet: `${API_BASE_URL}/api/getallfleet/`,
  addFleet: `${API_BASE_URL}/api/addfleet/`,
  addTruck: `${API_BASE_URL}/api/addtruck/`,
  optimize: `${API_BASE_URL}/api/optimize/`,
  getLatestOptimization: `${API_BASE_URL}/api/getlatestoptimization/`,
  getAllOptimizations: `${API_BASE_URL}/api/getalloptimizations/`,
  getOptimization: `${API_BASE_URL}/api/getoptimization/`,
}; 