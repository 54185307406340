import React, { useState } from "react";
import { AnalyticsBox } from "./AnalyticsBox";
import { AnalyticsButton } from "./AnalyticsButton";
import { LineChart, XAxis, YAxis, CartesianGrid, Line, BarChart, Bar, Legend, Tooltip, ResponsiveContainer } from "recharts";

export const AnalyticsFinancials = ({ optimizationResults }) => {
  const [selectedChart, setSelectedChart] = useState("Daily Operation Cost");

  const dieselDailyCosts = optimizationResults?.data?.finance?.cost_overview?.daily_costs?.diesel || {
    vehicle_cost: 0,
    energy_cost: 0,
    insurance_cost: 0,
    maintenance_cost: 0,
    non_ze_penalties: 0
  };
  
  const elctricDailyCosts = optimizationResults?.data?.finance?.cost_overview?.daily_costs?.electric || {
    vehicle_cost: 0,
    depot_energy: 0,
    depot_demand: 0,
    public_charging_energy: 0,
    maintenance_cost: 0,
    insurance_cost: 0
  };

  // Get annual costs directly from the backend
  const dieselAnnualCosts = optimizationResults?.data?.finance?.cost_overview?.annual_costs?.diesel || {
    vehicle_cost: 0,
    energy_cost: 0,
    insurance_cost: 0,
    maintenance_cost: 0,
    non_ze_penalties: 0
  };
  
  const electricAnnualCosts = optimizationResults?.data?.finance?.cost_overview?.annual_costs?.electric || {
    vehicle_cost: 0,
    depot_energy: 0,
    depot_demand: 0,
    public_charging_energy: 0,
    maintenance_cost: 0,
    insurance_cost: 0
  };

  const cumulativeCostData = Array.from(
    { length: optimizationResults?.data?.finance?.tco_analysis?.parameters?.years + 1 || 11 }, 
    (_, i) => ({
      year: i,
      Diesel: optimizationResults?.data?.finance?.tco_analysis?.cumulative_costs?.pure_diesel_fleet[i] || 0,
      Electric: optimizationResults?.data?.finance?.tco_analysis?.cumulative_costs?.optimized_fleet[i] || 0
    })
  );

  const chartData = {
    "Daily Operation Cost": [
      {
        category: "Energy Cost",
        Diesel: dieselDailyCosts.energy_cost || 0,
        Electric: (
          (elctricDailyCosts.depot_energy || 0) +
          (elctricDailyCosts.depot_demand || 0) +
          (elctricDailyCosts.public_charging_energy || 0)
        ),
      },
      {
        category: "Vehicle Cost",
        Diesel: dieselDailyCosts.vehicle_cost || 0,
        Electric: elctricDailyCosts.vehicle_cost || 0,
      },
      {
        category: "Insurance Cost",
        Diesel: dieselDailyCosts.insurance_cost || 0,
        Electric: elctricDailyCosts.insurance_cost || 0,
      },
      {
        category: "Maintenance Cost",
        Diesel: dieselDailyCosts.maintenance_cost || 0,
        Electric: elctricDailyCosts.maintenance_cost || 0,
      },
      {
        category: "Non-ZEV Penalties",
        Diesel: dieselDailyCosts.non_ze_penalties || 0,
        Electric: 0, // No penalties in electric costs
      }
    ],
    "Annual Operation Cost": [
      {
        category: "Energy Cost",
        Diesel: dieselAnnualCosts.energy_cost || 0,
        Electric: (
          (electricAnnualCosts.depot_energy || 0) +
          (electricAnnualCosts.depot_demand || 0) +
          (electricAnnualCosts.public_charging_energy || 0)
        ),
      },
      {
        category: "Vehicle Cost",
        Diesel: dieselAnnualCosts.vehicle_cost || 0,
        Electric: electricAnnualCosts.vehicle_cost || 0,
      },
      {
        category: "Insurance Cost",
        Diesel: dieselAnnualCosts.insurance_cost || 0,
        Electric: electricAnnualCosts.insurance_cost || 0,
      },
      {
        category: "Maintenance Cost",
        Diesel: dieselAnnualCosts.maintenance_cost || 0,
        Electric: electricAnnualCosts.maintenance_cost || 0,
      },
      {
        category: "Non-ZEV Penalties",
        Diesel: dieselAnnualCosts.non_ze_penalties || 0,
        Electric: 0, // No penalties in electric costs
      }
    ],
    "Upfront Cost": [
      {
        category: "Vehicles",
        Diesel: optimizationResults?.data?.finance?.cost_overview?.upfront_costs?.diesel?.vehicle_cost || 0,
        Electric: optimizationResults?.data?.finance?.cost_overview?.upfront_costs?.electric?.vehicle_cost || 0,
        description: "Total vehicle purchase costs"
      },
      {
        category: "Depot Construction",
        Diesel: 0, // Diesel doesn't have depot construction costs
        Electric: optimizationResults?.data?.finance?.cost_overview?.upfront_costs?.electric?.depot_construction || 0,
        description: "Costs for depot construction and modifications"
      },
      {
        category: "Charger Installation",
        Diesel: 0, // Diesel doesn't have charger installation costs
        Electric: optimizationResults?.data?.finance?.cost_overview?.upfront_costs?.electric?.charger_installation || 0,
        description: "Costs for installing charging equipment"
      },
      {
        category: "Power Interconnection",
        Diesel: 0, // Diesel doesn't have power interconnection costs
        Electric: optimizationResults?.data?.finance?.cost_overview?.upfront_costs?.electric?.power_interconnection || 0,
        description: "Costs for electrical grid connections and upgrades"
      },
      {
        category: "Total Infrastructure",
        Diesel: 0,
        Electric: (
          (optimizationResults?.data?.finance?.cost_overview?.upfront_costs?.electric?.depot_construction || 0) +
          (optimizationResults?.data?.finance?.cost_overview?.upfront_costs?.electric?.charger_installation || 0) +
          (optimizationResults?.data?.finance?.cost_overview?.upfront_costs?.electric?.power_interconnection || 0)
        ),
        description: "Total infrastructure costs",
        isTotal: true
      }
    ],
    "Cumulative Cost": cumulativeCostData
  };

  // Format all numbers to 2 decimal places
  Object.keys(chartData).forEach(section => {
    chartData[section].forEach(item => {
      item.Diesel = parseFloat((item.Diesel || 0).toFixed(2));
      item.Electric = parseFloat((item.Electric || 0).toFixed(2));
    });
  });

  // Function to determine if the chart should be a line chart or bar chart
  const isLineChart = (chartType) => {
    return chartType === "Cumulative Cost";
  };

  return (
    <>
      <div className="container-flex-row">
        <div className="dashboard-container route-results">
          <div className="overview">
            <AnalyticsBox 
              title={"Daily Operation Cost"} 
              value={optimizationResults?.data?.finance?.cost_overview?.daily_costs?.total || 0} 
            />
            <AnalyticsBox 
              title={"Annual Operation Cost"} 
              value={optimizationResults?.data?.finance?.cost_overview?.annual_costs?.total || 0} 
            />
            <AnalyticsBox 
              title={"Upfront Cost"} 
              value={optimizationResults?.data?.finance?.cost_overview?.upfront_costs?.total || 0} 
            />
            <AnalyticsBox
              title={"Total Cost of Ownership (10 years)"}
              value={optimizationResults?.data?.finance?.tco_analysis?.financial_metrics?.total_cost_of_ownership?.optimized_fleet || 0}
            />
          </div>
        </div>
      </div>
      <div className="container-flex-row cost-breakdown-container">
        <div className="container-flex-col analytics-button-sidebar">
          {Object.keys(chartData).map((key) => (
            <AnalyticsButton
              key={key}
              label={key}
              handler={setSelectedChart}
              isSelected={selectedChart === key}
            />
          ))}
        </div>
        <div className="cost-breakdown">
          <ResponsiveContainer width="100%" height={350}>
            {isLineChart(selectedChart) ? (
              <LineChart data={chartData[selectedChart]} margin={{ top: 20, right: 30, left: 25, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="year"
                  label={{ value: "Year", position: "insideBottom", offset: 0 }}
                />
                <YAxis
                  tickFormatter={(value) => `$${value.toLocaleString()}`}
                />
                <Tooltip
                  formatter={(value) => `$${value.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}`}
                  labelFormatter={(label) => `Year ${label}`}
                />
                <Legend
                  align="center"
                  verticalAlign="bottom"
                  height={36}
                  iconSize={12}
                  wrapperStyle={{
                    paddingTop: "10px",
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="Diesel"
                  stroke="#ff6384"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                  name="Pure Diesel"
                />
                <Line
                  type="monotone"
                  dataKey="Electric"
                  stroke="#36a2eb"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                  name="Optimized Fleet"
                />
              </LineChart>
            ) : (
              <BarChart data={chartData[selectedChart]} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="category" />
                <YAxis
                  tickFormatter={(value) => `$${value.toLocaleString()}`}
                />
                <Tooltip
                  formatter={(value) => `$${value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}`}
                  labelFormatter={(label) => {
                    const item = chartData[selectedChart].find(item => item.category === label);
                    return `${label}${item?.description ? `: ${item.description}` : ''}`;
                  }}
                />
                <Legend />
                <Bar
                  dataKey="Diesel"
                  fill="#ff6384"
                  name="Diesel"
                />
                <Bar
                  dataKey="Electric"
                  fill="#36a2eb"
                  name="Electric"
                />
              </BarChart>
            )}
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
}; 