import React from "react";
import { AnalyticsButton } from "./AnalyticsButton";
import { AnalyticsMap } from "./AnalyticsMap";
import { AnalyticsRouteSelection } from "./AnalyticsRouteSelection";
import { AnalyticsDepotSelection } from "./AnalyticsDepotSelection";
import { AnalyticsChargingSelection } from "./AnalyticsChargingSelection";

export const AnalyticsOperationalRecommendations = ({
  optimizationResults,
  selectedInfoType,
  setSelectedInfoType,
  highlightMapRoute,
  setHighlightMapRoute,
  highlightedDepot,
  setHighlightedDepot,
  highlightedChargingStation,
  setHighlightedChargingStation,
  showUnusedStations,
  setShowUnusedStations,
}) => {
  const handleHighlightedRouteChange = (routeId) => {
    setHighlightMapRoute(routeId);
  };

  const handleHighlightedDepotChange = (depotId) => {
    setHighlightedDepot(depotId);
  };

  const handleHighlightedChargingStationChange = (csId) => {
    setHighlightedChargingStation(csId);
  };

  return (
    <>
      <div className="container-flex-col route-summary">
        <div className="info-type-selector">
          <AnalyticsButton
            label="Routes"
            handler={() => setSelectedInfoType("routes")}
            isSelected={selectedInfoType === "routes"}
          />
          <AnalyticsButton
            label="Depots"
            handler={() => setSelectedInfoType("depots")}
            isSelected={selectedInfoType === "depots"}
          />
          <AnalyticsButton
            label="Public Chargers"
            handler={() => setSelectedInfoType("charging")}
            isSelected={selectedInfoType === "charging"}
          />
        </div>
        <div className="map-analytics-container">
          <h4>
            {selectedInfoType === "routes"
              ? "Select Route to View"
              : selectedInfoType === "depots"
              ? "Select Depot to View"
              : "Select Charging Station to View"}
          </h4>
          <div className="container-flex-row">
            <div className="route-sidebar">
              {selectedInfoType === "routes" &&
                Object.values(
                  optimizationResults.data.operation.routes
                ).map((route) => (
                  <AnalyticsRouteSelection
                    key={route.route_id}
                    route={route}
                    onSelect={handleHighlightedRouteChange}
                    isSelected={highlightMapRoute === route.route_id}
                  />
                ))}
              {selectedInfoType === "depots" &&
                Object.values(
                  optimizationResults.data.operation.depots
                ).map((depot) => {
                  // Get matching cost data for this depot
                  const costData = optimizationResults.data.finance.depot_costs[depot.depot_id] || {};
                  // Merge the operation and cost data
                  const mergedDepot = {
                    ...depot,
                    ...costData
                  };
                  return (
                    <AnalyticsDepotSelection
                      key={depot.depot_id}
                      depot={mergedDepot}
                      onSelect={handleHighlightedDepotChange}
                      isSelected={highlightedDepot === depot.depot_id}
                    />
                  );
                })}
              {selectedInfoType === "charging" &&
                Object.values(
                  optimizationResults.data.operation.charging_stations
                )
                  .map(station => {
                    // Get matching cost data for this station
                    const costData = optimizationResults.data.finance.charging_station_costs[station.cs_id] || {};
                    // Merge the operation and cost data
                    const mergedStation = {
                      ...station,
                      ...costData,
                      hideUnused: !showUnusedStations
                    };

                    return mergedStation;
                  })
                  .filter((station) => showUnusedStations || station.is_utilized)
                  .map((station) => (
                    <AnalyticsChargingSelection
                      key={station.cs_id}
                      station={station}
                      onSelect={handleHighlightedChargingStationChange}
                      isSelected={highlightedChargingStation === station.cs_id}
                    />
                  ))}
            </div>
            <div className="map-analytics">
              <AnalyticsMap
                routes={
                  optimizationResults.data.operation.routes
                }
                selectedRouteId={highlightMapRoute}
                selectedInfoType={selectedInfoType}
                depots={
                  optimizationResults.data.operation.depots
                }
                selectedDepot={highlightedDepot}
                selectedChargingStation={highlightedChargingStation}
              />
            </div>
          </div>
          {selectedInfoType === "charging" && (
            <div className="filter-controls">
              <label>
                <input
                  type="checkbox"
                  checked={showUnusedStations}
                  onChange={() => setShowUnusedStations(!showUnusedStations)}
                />
                Show all charging stations
              </label>
            </div>
          )}
        </div>
      </div>
    </>
  );
}; 