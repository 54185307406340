import '../App.css';
import { HomeButton } from '../components/website/HomeButton';

function UnderConstruction() {
    return (
        <div className='col-md-8 col-md-offset-2 text-center' style={{paddingTop: "5%"}}>
            <div style={{display: "flex", justifyContent: "center", marginBottom: "0px"}}>
                <HomeButton />
            </div>
            <h2 style={{paddingTop: "10%"}}>Pardon our dust!</h2>
            <h3>This page is still under construction. Click on the logo to go back to the home page.</h3>
        </div>
    )
}

export default UnderConstruction;