import React, { useState } from 'react';
import { RunHistoryTable } from '../../pro_calculator/tables/RunHistoryTable';

export const AnalyticsCaseHistory = ({ onViewCase }) => {
  const [expanded, setExpanded] = useState(false);

  const togglePanel = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="dashboard-container run-history">
      <div className="parameter-panel">
        <div
          className="panel-header"
          onClick={togglePanel}
        >
          <h3>Click to Unfold</h3>
          <span className={`panel-toggle ${expanded ? 'expanded' : ''}`}>
            {expanded ? '−' : '+'}
          </span>
        </div>

        <div className={`panel-content ${expanded ? 'expanded' : ''}`}>
          <RunHistoryTable onViewCase={onViewCase} />
        </div>
      </div>
    </div>
  );
}; 