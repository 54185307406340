import React from "react";
import "./css/AnalyticsButton.css";

export const AnalyticsButton = ({
    label,
    handler,
    isSelected
}) => {
    return (
        <div className="analytics-button-container">
            <button 
                className={`analytics-button-select ${isSelected ? "selected" : "" }`}
                onClick={() => {handler(label)}}> {label}</button>
        </div>
    )
}