import '../App.css';
import React from "react";
import { HomeButton } from '../components/website/HomeButton';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../config/apiConfig';

function CreateAccount () {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(API_ENDPOINTS.register, {
                first_name,
                last_name,
                email,
                username,
                password
            });
            console.log(response);
            setSuccess('Account created successfully!');
            setError('');
            console.log(response);
            navigate('/');
        } catch(error) {
            if (password !== confirmPassword) {
                setError('Passwords do not match.');
                return;
            }
            setError('Error creating account.');
            return;
        }
    }

    return (
        <div>
            <HomeButton />
            <div id="create-account" className='container'>
                <div className='row vertical-center-row'>
                    <div className='col-xs-6 col-xs-offset-3'>
                        <h3>Create an account</h3>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label for="inputUsername">First name</label>
                                <input type="text" className='form-control' value={first_name} onChange={(e) => setFirstName(e.target.value)} required></input>
                            </div>
                            <div className='form-group'>
                                <label for="inputUsername">Last name</label>
                                <input type="text" className='form-control' value={last_name} onChange={(e) => setLastName(e.target.value)} required></input>
                            </div>
                            <div className='form-group'>
                                <label for="inputEmail">Email</label>
                                <input type="email" className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} required></input>
                            </div>
                            <div className='form-group'>
                                <label for="inputUsername">Username</label>
                                <input type="text" className='form-control' value={username} onChange={(e) => setUsername(e.target.value)} required></input>
                            </div>
                            <div className='form-group'>
                                <label for="inputPassword">Password</label>
                                <input type="password" className='form-control' value={password} onChange={(e) => setPassword(e.target.value)} required></input>
                            </div>
                            <div className='form-group'>
                                <label for="confirmPassword">Confirm your password</label>
                                <input type="password" className='form-control' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required></input>
                            </div>
                            <button className='btn btn-default'>Create an account</button>
                        </form>
                        {error && <p className='text-danger'>{error}</p>}
                        {success && <p className='text-success'>{success}</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAccount;