import '../App.css';
import './css/LoginLandingPage.css';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeButton } from '../components/website/HomeButton';
import axios from 'axios';
import { API_ENDPOINTS } from '../config/apiConfig';

axios.defaults.withCredentials = true;

function LoginLanding() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [newUserUsername, setNewUserUsername] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [newUserConfirmPassword, setNewUserConfirmPassword] = useState('');
    const [newUserFirstName, setNewUserFirstName] = useState('');
    const [newUserLastName, setNewUserLastName] = useState('');
    const [newUserCompanyName, setNewUserCompanyName] = useState('');
    const [newUserPhoneNumber, setNewUserPhoneNumber] = useState('');

    const [error, setError] = useState('');
    const [loginState, setLoginState] = useState('login');
    const [email, setEmail] = useState('');

    const errorTimeoutRef = useRef(null);

    const navigate = useNavigate();

    const clearError = () => {
        setError('');
        if (errorTimeoutRef.current) {
            clearTimeout(errorTimeoutRef.current);
            errorTimeoutRef.current = null;
        }
    };

    useEffect(() => {
        if (error) {
            if (errorTimeoutRef.current) {
                clearTimeout(errorTimeoutRef.current);
            }
            
            errorTimeoutRef.current = setTimeout(() => {
                setError('');
                errorTimeoutRef.current = null;
            }, 15000);
        }
        
        return () => {
            if (errorTimeoutRef.current) {
                clearTimeout(errorTimeoutRef.current);
            }
        };
    }, [error]);

    useEffect(() => {
        const handleUserInteraction = () => {
            if (error) {
                clearError();
            }
        };
        
        document.addEventListener('click', handleUserInteraction);
        document.addEventListener('keydown', handleUserInteraction);
        
        return () => {
            document.removeEventListener('click', handleUserInteraction);
            document.removeEventListener('keydown', handleUserInteraction);
        };
    }, [error]);

    const handleLogin = async (e) => {
        e.preventDefault();
        console.log('Login attempt initiated for username:', username);
        console.log('Full login URL:', API_ENDPOINTS.login);

        try {
            console.log('Making login request to:', API_ENDPOINTS.login);
            const response = await axios.post(API_ENDPOINTS.login, {
                username,
                password
            });
            console.log('Login successful, received token:', response.data.token ? 'Token exists' : 'No token received');

            localStorage.setItem('authToken', response.data.token);
            setError('');
            console.log('Navigating to home page');
            navigate('/');
        } catch (error) {
            console.error('Login failed:', error.response?.data || error.message);
            setError('Invalid username or password');
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault();
        console.log('Register attempt initiated for username:', newUserUsername);
        console.log('Full register URL:', API_ENDPOINTS.register);

        try {
            if (newUserPassword !== newUserConfirmPassword) {
                setError('Passwords do not match');
                return;
            }

            const response = await axios.post(API_ENDPOINTS.register, {
                username: newUserUsername,
                email: newUserEmail,
                password: newUserPassword,
                confirm_password: newUserConfirmPassword,
                first_name: newUserFirstName,
                last_name: newUserLastName,
                company_name: newUserCompanyName,
                phone_number: newUserPhoneNumber
            });
            console.log('Registration successful, received token:', response.data.token ? 'Token exists' : 'No token received');

            localStorage.setItem('authToken', response.data.token);
            setError('');
            console.log('Navigating to home page');
            navigate('/');
        } catch (error) {
            console.error('Registration failed:', error.response?.data || error.message);
            setError('Registration failed');
        }
    }

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        console.log('Forgot password attempt initiated for email:', email);

        try {
            const response = await axios.post(API_ENDPOINTS.forgotPassword, {
                email
            });
            console.log('Password reset email sent successfully');
        } catch (error) {
            console.error('Password reset failed:', error.response?.data || error.message);
            setError('Failed to send password reset email');
        }
    }

    const renderLoginForm = () => {
        switch (loginState) {
            case 'login':
                return (
                    <div className="login-form-wrapper">
                        <div className="login-form-container">
                            <div className="login-form-body">
                                <form onSubmit={handleLogin}>
                                    <div className="login-form-group">
                                        <label htmlFor="email">Email or Username</label>
                                        <input type="text" className="form-control" id="email" onChange={(e) => setUsername(e.target.value)} required />
                                    </div>
                                    <div className="login-form-group">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" id="password" onChange={(e) => setPassword(e.target.value)} required />
                                    </div>
                                    <div className="login-button-container">
                                        <button type="submit" className='login-button'>Login</button>
                                    </div>
                                </form>
                                {error && <p className='text-danger'>{error}</p>}
                                <div className="g-signin2" data-onsuccess="onSignIn"></div>

                                <div className="text-center mt-3">
                                    <button className='btn btn-link' onClick={() => setLoginState('forgot')}>Forgot your password?</button>
                                </div>
                                <div className="text-center mt-3">
                                    <div className="login-form-text-container">
                                        <span className='login-form-text'>Don't have an account?</span>
                                        <button className='btn btn-link' onClick={() => setLoginState('register')}>Sign up now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'register':
                return (
                    <div className="login-form-wrapper">
                        <div className="login-form-container">
                            <div className="login-form-body">
                                <div className='login-form-text-container'>
                                    <p className='login-form-text'>Create an account</p>
                                </div>
                                <form onSubmit={handleRegister}>
                                    <div className='login-name-container'>
                                        <div className="login-form-group">
                                            <label htmlFor="first-name">First Name <span className="required-asterisk">*</span></label>
                                            <input type="text" className="form-control" id="first-name" onChange={(e) => setNewUserFirstName(e.target.value)} required />
                                        </div>
                                        <div className="login-form-group">
                                            <label htmlFor="last-name">Last Name <span className="required-asterisk">*</span></label>
                                            <input type="text" className="form-control" id="last-name" onChange={(e) => setNewUserLastName(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="login-form-group">
                                        <label htmlFor="username">Username <span className="required-asterisk">*</span></label>
                                        <input type="text" className="form-control" id="username" onChange={(e) => setNewUserUsername(e.target.value)} required />
                                    </div>
                                    <div className="login-form-group">
                                        <label htmlFor="email">Email <span className="required-asterisk">*</span></label>
                                        <input type="email" className="form-control" id="email" onChange={(e) => setNewUserEmail(e.target.value)} required />
                                    </div>
                                    <div className="login-form-group">
                                        <label htmlFor="password">Password <span className="required-asterisk">*</span></label>
                                        <input type="password" className="form-control" id="password" onChange={(e) => setNewUserPassword(e.target.value)} required />
                                    </div>
                                    <div className="login-form-group">
                                        <label htmlFor="confirm-password">Confirm Password <span className="required-asterisk">*</span></label>
                                        <input type="password" className="form-control" id="confirm-password" onChange={(e) => setNewUserConfirmPassword(e.target.value)} required />
                                    </div>
                                    <div className="login-form-group">
                                        <label htmlFor="company-name">Company Name</label>
                                        <input type="text" className="form-control" id="company-name" onChange={(e) => setNewUserCompanyName(e.target.value)} />
                                    </div>
                                    <div className="login-form-group">
                                        <label htmlFor="phone-number">Phone Number</label>
                                        <input type="text" className="form-control" id="phone-number" onChange={(e) => setNewUserPhoneNumber(e.target.value)} />
                                    </div>
                                    <div className="required-fields-note">
                                        <span className="required-asterisk">*</span> Required fields
                                    </div>
                                    <div className="login-button-container">
                                        <button type="submit" className='login-button'>Create Account</button>
                                    </div>
                                </form>
                                {error && <p className='text-danger'>{error}</p>}
                                <div className="login-form-text-container">
                                    <span className='login-form-text'>Already have an account?</span>
                                    <button className='btn btn-link' onClick={() => setLoginState('login')}>Back to login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'forgot':
                return (
                    <div className="login-form-wrapper">
                        <div className="login-form-container">
                            <div className="login-form-body">
                                <div className='login-form-text-container'>
                                    <p className='login-form-text'>Forgot your password? Enter your email below and we will send you a link to reset your password.</p>
                                </div>
                                <form onSubmit={handleForgotPassword}>
                                    <div className="login-form-group">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" className="form-control" id="email" placeholder="e.x. johndoe@gmail.com" onChange={(e) => setEmail(e.target.value)} required />
                                    </div>
                                    <div className="login-button-container">
                                        <button type="submit" className='login-button'>Reset Password</button>
                                    </div>
                                </form>
                                {error && <p className='text-danger'>{error}</p>}
                                <div className="login-form-text-container">
                                    <button className='btn btn-link' onClick={() => setLoginState('login')}>Back to login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        };
    }

    return (
        <div className='login-landing-container'>
            <script src="https://apis.google.com/js/platform.js" async defer></script>
            <div className='login-background-half'>
                <img src={'/img/ev-truck-fleet.png'} alt="EV Truck Fleet" />
                <div className='login-image-overlay'>
                    <p>Empower your fleet</p>
                </div>
            </div>
            <div className='login-form-half'>
                <div className='login-home-button-container'>
                    <HomeButton />
                </div>
                {renderLoginForm()}
            </div>
        </div>
    );
}

export default LoginLanding;