import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom"; // Import ReactDOM for createPortal
import "./SelectionTable.css"; // Import the CSS file

const SelectionTable = ({
  items,
  selectedItems,
  handleCheckboxChange,
  handleSelectAll,
  allSelected,
  columns,
  itemIdKey,
  renderRow,
  filteredItems = items,
  onFilterChange = () => {}
}) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [filters, setFilters] = useState({});
  const filterDropdownRef = useRef(null);
  const filterButtonRefs = useRef({});
  const [searchTerm, setSearchTerm] = useState("");
  
  // Add this to compute filtered items internally based on filters
  const displayedItems = React.useMemo(() => {
    // If no filters are applied, show all items (or filteredItems if provided)
    if (Object.keys(filters).length === 0) return filteredItems;
    
    // Apply filters
    return filteredItems.filter(item => {
      // An item passes if it matches ALL filters (across different columns)
      return Object.entries(filters).every(([columnKey, allowedValues]) => {
        // If no values are selected for this column or the array is empty, don't filter on this column
        if (!allowedValues || allowedValues.length === 0) return true;
        
        // Check if the item's value for this column is in the allowed values
        return allowedValues.includes(item[columnKey]);
      });
    });
  }, [filteredItems, filters]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterDropdownRef.current && !filterDropdownRef.current.contains(event.target)) {
        setActiveFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getUniqueValues = (columnKey) => {
    const uniqueValues = [];
    items.forEach(item => {
      const value = item[columnKey];
      if (!uniqueValues.includes(value) && value !== undefined && value !== null) {
        uniqueValues.push(value);
      }
    });
    return uniqueValues.sort();
  };

  const toggleFilter = (columnKey) => {
    setActiveFilter(activeFilter === columnKey ? null : columnKey);
  };

  const handleFilterSelect = (columnKey, value, isChecked) => {
    const currentFilters = { ...filters };
    
    if (!currentFilters[columnKey]) {
      currentFilters[columnKey] = [];
    }
    
    if (isChecked) {
      currentFilters[columnKey].push(value);
    } else {
      currentFilters[columnKey] = currentFilters[columnKey].filter(v => v !== value);
      if (currentFilters[columnKey].length === 0) {
        delete currentFilters[columnKey];
      }
    }
    
    setFilters(currentFilters);
    onFilterChange(currentFilters);
  };

  const clearColumnFilter = (columnKey) => {
    const currentFilters = { ...filters };
    delete currentFilters[columnKey];
    setFilters(currentFilters);
    onFilterChange(currentFilters);
  };

  // Function to calculate the position of the dropdown
  const getDropdownPosition = (columnKey) => {
    const buttonRect = filterButtonRefs.current[columnKey]?.getBoundingClientRect();
    
    if (!buttonRect) return { top: 0, left: 0 };
    
    return {
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.left + window.scrollX - 200, // Adjust this value to position the dropdown correctly
    };
  };

  // Function to render the filter dropdown using React Portal
  const renderFilterDropdown = (columnKey) => {
    if (activeFilter !== columnKey) return null;
    
    const position = getDropdownPosition(columnKey);
    
    // Filter values based on search term
    const filteredValues = getUniqueValues(columnKey).filter(value => 
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    return ReactDOM.createPortal(
      <div 
        className="filter-dropdown"
        ref={filterDropdownRef}
        style={{
          position: 'fixed',
          top: `${position.top}px`,
          left: `${position.left}px`
        }}
      >
        <div className="filter-search">
          <input 
            type="text" 
            placeholder="Search..." 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <div className="filter-options">
          <div className="filter-actions">
            <button onClick={() => clearColumnFilter(columnKey)}>
              Clear Filter
            </button>
          </div>
          <div className="filter-list">
            {filteredValues.map(value => (
              <div key={value} className="filter-option">
                <input
                  type="checkbox"
                  id={`filter-${columnKey}-${value}`}
                  checked={filters[columnKey]?.includes(value) || false}
                  onChange={(e) => handleFilterSelect(columnKey, value, e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                />
                <label htmlFor={`filter-${columnKey}-${value}`}>
                  {value}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>,
      document.body
    );
  };

  // Reset search term when changing active filter
  useEffect(() => {
    setSearchTerm("");
  }, [activeFilter]);

  return (
    <div className="table-container">
      <table className="parameter-selection table">
        <thead>
          <tr>
            <th className="checkbox-column" style={{ width: '5px' }}>
              <input
                type="checkbox"
                checked={allSelected}
                onChange={handleSelectAll}
              />
            </th>
            {columns.map((column) => (
              <th key={column.key} style={{ width: column.width }}>
                <div className="column-header">
                  <span>{column.label}</span>
                  <div className="filter-container">
                    <button 
                      ref={el => filterButtonRefs.current[column.key] = el}
                      className={`filter-button ${filters[column.key] ? 'active-filter' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFilter(column.key);
                      }}
                    >
                      <svg 
                        width="16" 
                        height="16" 
                        viewBox="0 -6 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2"
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                        style={{ marginTop: '2px' }}
                      >
                        <polygon points="22 -2 2 -2 10 7.46 10 14 14 16 14 7.46 22 -2"/>
                      </svg>
                    </button>
                    {renderFilterDropdown(column.key)}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {displayedItems.map((item) => {
            const itemId = item[itemIdKey];
            return (
              <tr
                key={itemId}
                className={`table-row ${selectedItems.includes(itemId) ? 'selected-row' : ''}`}
                onClick={() => handleCheckboxChange(itemId)}
              >
                <td className="checkbox-column" style={{ width: '5px' }} onClick={(e) => e.stopPropagation()}>
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(itemId)}
                    onChange={(e) => handleCheckboxChange(itemId, e)}
                  />
                </td>
                {renderRow(item)}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SelectionTable;