import React, { useState } from 'react';
import './css/fleetPlanningDashboard.css'; // We'll create this CSS file next
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import "../../App.css";

const FleetPlanningDashboard = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <div className="dashboard-container">
          <div className="dashboard-card">
            <div className="construction-icon">🚧</div>
            <h1 className="dashboard-title">Dashboard</h1>
            <h3 className="dashboard-subtitle">Under Construction</h3>
            <p className="dashboard-message">
              We're working on building an amazing dashboard experience for you.
              Please check back soon!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FleetPlanningDashboard;
