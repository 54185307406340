import React from 'react';
import './Notification.css';

export const Notification = ({ message, type, visible }) => {
  if (!visible) return null;
  
  return (
    <div className={`notification ${type}`}>
      <p>{message}</p>
    </div>
  );
}; 