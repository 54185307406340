import './css/ReviewAndOptimize.css';
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from '../../../config/apiConfig';
import { expandEnergyChargeData } from "./TOUSettings";

const ReviewAndOptimize = ({
  selectedRoutes,
  selectedDepots,
  selectedChargingStations,
  selectedTrucks,
  truckCustomData,
  routes,
  depots,
  chargingStations,
  trucks,
  energyChargeData,
  otherParameters,
  prevStep,
  setLoadingOverlay
}) => {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const [showCaseNameModal, setShowCaseNameModal] = useState(false);
  const [caseName, setCaseName] = useState("");

  // Handle optimization
  const handleOptimize = async (e) => {
    e.preventDefault();
    setLoadingOverlay(true);

    const expandedEnergyCharges = expandEnergyChargeData(energyChargeData);
    
    try {
      const response = await axios.post(
        API_ENDPOINTS.optimize,
        {
          case_name: caseName,
          selected_routes: selectedRoutes,
          selected_depots: selectedDepots,
          selected_charging_stations: selectedChargingStations,
          selected_trucks: selectedTrucks,
          truck_custom_data: truckCustomData,
          tou_rates: expandedEnergyCharges,
          other_parameters: otherParameters,
        },
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      navigate("/analytics");
    } catch (error) {
      console.log("error", error);
      setLoadingOverlay(false);
    }
  };

  // Open case name modal
  const openCaseNameModal = () => {
    setShowCaseNameModal(true);
  };

  // Handle case name submit
  const handleCaseNameSubmit = (e) => {
    e.preventDefault();
    setShowCaseNameModal(false);
    handleOptimize(e);
  };

  // Suggested case names
  const suggestedCaseNames = [
    "Typical Day 1",
    "Busy Day",
    "Weekend Operations",
    "Holiday Schedule",
    "Peak Season"
  ];
  
  // Apply suggested case name
  const applySuggestion = (suggestion) => {
    setCaseName(suggestion);
  };

  // Get selected items for summary
  const selectedTruckItems = trucks.filter(truck =>
    selectedTrucks.includes(truck.truck_id)
  ).map(truck => ({
    ...truck,
    price: truckCustomData[truck.truck_id]?.price || truck.price,
    incentives: truckCustomData[truck.truck_id]?.incentives || truck.incentives
  }));

  const selectedRouteItems = routes.filter(route =>
    selectedRoutes.includes(route.id)
  );

  const selectedDepotItems = depots.filter(depot =>
    selectedDepots.includes(depot.depot_id)
  );

  const selectedChargingStationItems = chargingStations.filter(station =>
    selectedChargingStations.includes(station.id)
  );

  return (
    <div className="step-container">
      <h2>Step 7: Review & Optimize</h2>
      <p>Review your selections before running the optimization.</p>

      <div className="summary-container">
        <div className="summary-section">
          <h3>Selected Routes</h3>
          <ul className="summary-list">
            {selectedRouteItems.map(route => (
              <li key={route.id}>
                <p>{route.raw_route_name}</p>
                <span className="summary-detail">
                  <span>
                    {route.addresses[0]} to {route.addresses[route.addresses.length - 1]}
                  </span>
                  <span>
                    {(route.distances.reduce((a, b) => a + b, 0) / 1609.34).toFixed(1)} mi
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="summary-section">
          <h3>Selected Depots</h3>
          <ul className="summary-list">
            {selectedDepotItems.map(depot => (
              <li key={depot.depot_id}>
                {depot.name || `Depot ${depot.depot_id}`}
                <span className="summary-detail">
                  ({depot.address})
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="summary-section">
          <h3>Selected Charging Stations</h3>
          <ul className="summary-list">
            {selectedChargingStationItems.length > 0 ? (
              selectedChargingStationItems.map(station => (
                <li key={station.id}>
                  {station.name || `Station ${station.id}`}
                  <span className="summary-detail">
                    {station.max_power} kW - {station.addr || `${station.lat}, ${station.lon}`}
                  </span>
                </li>
              ))
            ) : (
              <li>No charging stations selected</li>
            )}
          </ul>
        </div>

        <div className="summary-section">
          <h3>Selected Vehicles</h3>
          <ul className="summary-list">
            {selectedTruckItems.map(truck => (
              <li key={truck.truck_id}>
                {truck.make} {truck.model} - ${truck.price.toLocaleString()}
                <span className="summary-detail">
                  ({truck.batt_cap} kWh, {truck.operation_range} miles range)
                  {truck.incentives > 0 && ` - $${truck.incentives.toLocaleString()} incentives`}
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="summary-section">
          <h3>Time-of-Use Rates</h3>
          <div className="tou-summary">
            <ul className="tou-summary-list">
              <li key="peak-rate"><span className="tou-summary-detail">Peak rate: ${Math.max(...energyChargeData.map(d => d.y)).toFixed(4)}/kWh</span> </li>
              <li key="off-peak-rate"><span className="tou-summary-detail">Off-peak rate: ${Math.min(...energyChargeData.map(d => d.y)).toFixed(4)}/kWh</span></li>
              <li key="average-rate"><span className="tou-summary-detail">Average rate: ${(energyChargeData.reduce((sum, d) => sum + d.y, 0) / 24).toFixed(4)}/kWh</span></li>
            </ul>
          </div>
        </div>

        <div className="summary-section">
          <h3>Key Parameters</h3>
          <div className="parameters-summary">
            <ul className="parameters-summary-list">
              <li key="diesel-truck-cost"><span className="parameters-summary-detail">Diesel truck cost: ${parseFloat(otherParameters.diesel_price).toLocaleString()}</span></li>
              <li key="diesel-maintenance"><span className="parameters-summary-detail">Diesel maintenance: ${otherParameters.diesel_maint}/mile</span></li>
              <li key="electric-maintenance"><span className="parameters-summary-detail">Electric maintenance: ${otherParameters.elec_maint}/mile</span></li>
              <li key="depot-construction"><span className="parameters-summary-detail">Depot construction: ${parseFloat(otherParameters.depot_const_cost).toLocaleString()}</span></li>
              <li key="non-zev-penalty"><span className="parameters-summary-detail">Non-ZEV penalty: ${otherParameters.non_ze_penalty}/day</span></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="step-actions">
        <button className="back-btn" onClick={prevStep}>
          Back
        </button>
        <button
          className="optimize-btn"
          onClick={openCaseNameModal}
          disabled={selectedTrucks.length === 0 || selectedRoutes.length === 0 }
        >
          Run Optimization
        </button>
      </div>

      {/* Case Name Modal */}
      {showCaseNameModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Name Your Case</h3>
            <p>Give this optimization case a descriptive name:</p>
            
            <form onSubmit={handleCaseNameSubmit}>
              <input
                type="text"
                value={caseName}
                onChange={(e) => setCaseName(e.target.value)}
                placeholder="Enter case name"
                required
              />
              
              <div className="suggested-names">
                <p>Suggested names:</p>
                <div className="suggestion-buttons">
                  {suggestedCaseNames.map((suggestion, index) => (
                    <button
                      key={index}
                      type="button"
                      onClick={() => applySuggestion(suggestion)}
                      className="suggestion-btn"
                    >
                      {suggestion}
                    </button>
                  ))}
                </div>
              </div>
              
              <div className="modal-actions">
                <button 
                  type="button" 
                  className="cancel-btn"
                  onClick={() => setShowCaseNameModal(false)}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className="confirm-btn"
                  disabled={!caseName.trim()}
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewAndOptimize; 