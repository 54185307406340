import React, { useState } from 'react';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend, ResponsiveContainer } from "recharts";
import './css/AnalyticsChargingSelection.css';
import { AnalyticsTooltip } from './common/AnalyticsTooltip';

export const AnalyticsChargingSelection = ({
    station,
    onSelect,
    isSelected
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 50, y: 50 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

    const handleClick = () => {
        // Toggle selection - if already selected, unselect it by passing null
        onSelect(isSelected ? null : station.cs_id);
    };
    const showDetails = (e) => {
        e.stopPropagation();
        setShowPopup(true);
    };

    const closePopup = (e) => {
        e.stopPropagation();
        setShowPopup(false);
    };

    // Popup dragging handlers
    const handleMouseDown = (e) => {
        if (e.target.className === 'popup-header' || e.target.className === 'popup-drag-handle') {
            setIsDragging(true);
            setDragOffset({
                x: e.clientX - popupPosition.x,
                y: e.clientY - popupPosition.y
            });
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            setPopupPosition({
                x: e.clientX - dragOffset.x,
                y: e.clientY - dragOffset.y
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Format currency values
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(value);
    };

    // Prepare charging schedule data for the chart
    const prepareChargingScheduleData = () => {
        if (!station.charging_schedule) {
            return [];
        }
        
        const mappedData = Object.entries(station.charging_schedule).map(([timePeriod, data]) => {
            const timeIndex = parseInt(timePeriod, 10);
            const totalMinutes = timeIndex * 15;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            const timeOfDay = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

            return {
                timePeriod,
                timeOfDay,
                totalLoad: data.total_load || 0,
                electricityRate: data.electricity_rate || 0,
                chargingTrucks: data.charging_trucks || []
            };
        });
        
        const sortedData = mappedData.sort((a, b) => {
            return parseInt(a.timePeriod, 10) - parseInt(b.timePeriod, 10);
        });
        
        return sortedData;
    };

    const chargingScheduleData = prepareChargingScheduleData();
    const isUtilized = station.is_utilized;

    // Helper function to check if a string is a valid URL
    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    // Don't render non-utilized stations if they should be filtered out
    if (!isUtilized && station.hideUnused) {
        return null;
    }

    return (
        <div className="analytics-selection-item">
            <button
                className={`charging-station-selection ${isSelected ? 'station-selected' : ''} ${isUtilized ? 'station-utilized' : 'station-not-utilized'}`}
                onClick={handleClick}
                type="button"
                aria-pressed={isSelected}
            >
                <span className="station-name">Charging Station: {station.name}</span>
                <div className="station-details">
                    <span className="station-status">
                        Status: {isUtilized ? 
                            <span style={{ color: 'green' }}>Utilized</span> : 
                            <span style={{ color: 'red' }}>Not Utilized</span>}
                    </span>
                    <span className="station-energy">
                        Total Energy Delivered: {station.total_energy_delivered?.toFixed(2)} kWh
                    </span>
                    <span className="station-cost">
                        Daily Cost: {formatCurrency(station.total_daily_cost)}
                    </span>
                    
                    <div className="show-details-container">
                        <button 
                            className="show-details-button" 
                            onClick={showDetails}
                        >
                            Show Details
                        </button>
                    </div>
                </div>
            </button>

            {showPopup && (
                <div 
                    className="station-popup-overlay" 
                    onClick={closePopup}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                >
                    <div 
                        className="station-popup-content" 
                        onClick={e => e.stopPropagation()}
                        style={{
                            position: 'absolute',
                            left: `${popupPosition.x}px`,
                            top: `${popupPosition.y}px`,
                            cursor: isDragging ? 'grabbing' : 'auto'
                        }}
                    >
                        <div 
                            className="popup-header"
                            onMouseDown={handleMouseDown}
                            style={{ cursor: 'grab', padding: '10px', background: '#f0f0f0', borderBottom: '1px solid #ddd' }}
                        >
                            <div className="popup-drag-handle" style={{ display: 'inline-block' }}>
                                <h2 style={{ margin: 0, display: 'inline-block' }}>
                                    Charging Station {station.cs_id} Details
                                </h2>
                            </div>
                            <button className="close-popup" onClick={closePopup} style={{ float: 'right' }}>×</button>
                        </div>
                        
                        <div className="station-info">
                            <p><strong>Status:</strong> {isUtilized ? 
                                <span style={{color: 'green'}}>Utilized</span> : 
                                <span style={{color: 'red'}}>Not Utilized</span>}
                            </p>
                            <p><strong>Owner:</strong> {station.owner || 'N/A'}</p>
                            <p><strong>Additional Info:</strong> {
                                station.additional_info ? (
                                    isValidUrl(station.additional_info) ? 
                                        <a href={station.additional_info} target="_blank" rel="noopener noreferrer">
                                            {station.additional_info}
                                        </a> : 
                                        station.additional_info
                                ) : 'N/A'
                            }</p>
                            <p><strong>Total Energy Delivered:</strong> {station.total_energy_delivered?.toFixed(2)} kWh</p>
                            <p><strong>Daily Electricity Cost:</strong> {formatCurrency(station.daily_costs.energy_cost)}</p>
                            <p><strong>Daily Service Fee:</strong> {formatCurrency(station.daily_costs.additional_cost)}</p>
                            <p><strong>Total Daily Cost:</strong> {formatCurrency(station.total_daily_cost)}</p>
                        </div>
                        
                        {chargingScheduleData.length > 0 && (
                            <div className="charts-container">
                                <div className="chart-section">
                                    <h3>Charging Load Profile</h3>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <LineChart data={chargingScheduleData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="timeOfDay" />
                                            <YAxis label={{ value: "kW", angle: -90, position: "insideLeft" }} />
                                            <Tooltip content={(props) => <AnalyticsTooltip {...props} chartType="charging" showStopInfo={true} />} />
                                            <Legend />
                                            <Line 
                                                type="stepAfter" 
                                                dataKey="totalLoad" 
                                                name="Total Charging Load" 
                                                stroke="#ff7300" 
                                                dot={false} 
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>

                                <div className="chart-section">
                                    <h3>Electricity Rate</h3>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <LineChart data={chargingScheduleData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="timeOfDay" />
                                            <YAxis label={{ value: "$/kWh", angle: -90, position: "insideLeft" }} />
                                            <Tooltip content={(props) => <AnalyticsTooltip {...props} chartType="rate" />} />
                                            <Legend />
                                            <Line 
                                                type="stepAfter" 
                                                dataKey="electricityRate" 
                                                name="Electricity Rate ($/kWh)" 
                                                stroke="#8884d8" 
                                                dot={false} 
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};