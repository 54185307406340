import React, { useState, useEffect, useRef } from 'react';
import './ImageSlider.css'; // Import CSS for styling

const ImageSlider = ({ images, onTruckChange, selectedTruck }) => {
  const [currentTruckIndex, setCurrentTruckIndex] = useState(0);
  const [toggledTrucks, setToggledTrucks] = useState(
    Array(images.length).fill(false) // Track toggled state for each truck
  );
  const sliderRef = useRef(null);
  const timeoutRef = useRef(null); // To store the timeout ID for truck toggling

  useEffect(() => {
    if (selectedTruck) {
      const newIndex = images.findIndex(truck => truck.id === selectedTruck.id);
      if (newIndex !== -1) {
        setCurrentTruckIndex(newIndex);
      }
      timeoutRef.current = setTimeout(() => {
        setToggledTrucks((prevToggled) => {
          const newToggled = [...prevToggled];
          newToggled[currentTruckIndex] = true; // Toggle the center truck to its alternate
          return newToggled;
        });
      }, 1000); // 1-second delay for switching to the alternate truck

      // Clear any previous timeouts to avoid unexpected behavior
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    // Cleanup the timeout on component unmount
    return () => clearTimeout(timeoutRef.current);
  }, [selectedTruck, images, currentTruckIndex]);

  // Function to go to the previous truck
  const handlePrevClick = () => {
    const newIndex =
      currentTruckIndex === 0 ? images.length - 1 : currentTruckIndex - 1;

    setCurrentTruckIndex(newIndex);

    // Reset the toggled state for trucks other than the current one
    setToggledTrucks((prevToggled) =>
      prevToggled.map((toggled, i) => (i === newIndex ? toggled : false))
    );

    onTruckChange(images[newIndex].id); // Notify parent of the truck change
  };

  // Function to go to the next truck
  const handleNextClick = () => {
    const newIndex =
      currentTruckIndex === images.length - 1 ? 0 : currentTruckIndex + 1;

    setCurrentTruckIndex(newIndex);

    // Reset the toggled state for trucks other than the current one
    setToggledTrucks((prevToggled) =>
      prevToggled.map((toggled, i) => (i === newIndex ? toggled : false))
    );

    onTruckChange(images[newIndex].id); // Notify parent of the truck change
  };

  return (
    <div className="image-slider">
      <div className="slider-container">
        <button className="arrow-button left-arrow" onClick={handlePrevClick}>
          &lt;
        </button>
        <div className="images-wrapper" ref={sliderRef}>
            <div className="slider-image-container">
              <img
                src={
                  toggledTrucks[currentTruckIndex] 
                    ? process.env.PUBLIC_URL + images[currentTruckIndex].imgUrl 
                    : process.env.PUBLIC_URL + images[currentTruckIndex].logoUrl
                }
                alt={images[currentTruckIndex].title}
                className="slider-image active"
                onClick={() => setToggledTrucks((prevToggled) => {
                  const newToggled = [...prevToggled];
                  newToggled[currentTruckIndex] = !newToggled[currentTruckIndex];
                  return newToggled;
                })}
                style={{ width: "200px", height: "100px" }} />
            </div>
        </div>
        <button className="arrow-button right-arrow" onClick={handleNextClick}>
          &gt;
        </button>
      </div>
      {currentTruckIndex !== null && (
        <div className="image-info">
          {/* <h3 className='image-name'>{images[currentTruckIndex].title}</h3> */}
          {/* <p>{images[currentTruckIndex].description}</p> */}
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
