import React from 'react';

export const AnalyticsTooltip = ({ 
    active, 
    payload, 
    label, 
    chartType,
    showStopInfo = false
}) => {
    if (!active || !payload || !payload.length) return null;
    
    const dataPoint = payload[0].payload;
    const isCharging = dataPoint?.chargingPower > 0 || dataPoint?.totalLoad > 0;
    const isStopped = dataPoint?.stopRatio > 0;

    // Determine chart type if not explicitly provided
    const detectedChartType = chartType || (
        payload[0].name.includes("Charging") ? "charging" :
        payload[0].name.includes("State of Charge") ? "battery" :
        payload[0].name.includes("Electricity Rate") ? "rate" : "unknown"
    );
    
    // Format value based on chart type
    const formatValue = (value, type) => {
        const numValue = parseFloat(value).toFixed(type === "rate" ? 2 : 1);
        switch (type) {
            case "charging": return `${numValue} kW`;
            case "battery": return `${numValue}%`;
            case "rate": return `$${numValue}/kWh`;
            default: return numValue;
        }
    };
    
    return (
        <div className="custom-tooltip" style={{
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
            fontSize: '12px',
            minWidth: '180px'
        }}>
            <p style={{ 
                margin: '0 0 5px 0', 
                fontWeight: 'bold',
                borderBottom: '1px solid #eee', 
                paddingBottom: '5px' 
            }}>
                {label}
                {showStopInfo && isStopped && 
                    <span style={{ 
                        marginLeft: '5px',
                        color: '#666',
                        fontStyle: 'italic'
                    }}>
                        (Stopped: {Math.round(dataPoint.stopRatio * 100)}%)
                    </span>
                }
            </p>
            
            {payload.map((entry, index) => (
                <p key={`item-${index}`} style={{ 
                    margin: '3px 0',
                    color: entry.color
                }}>
                    <span style={{ fontWeight: 'bold' }}>{entry.name}:</span> {' '}
                    {formatValue(entry.value, detectedChartType)}
                </p>
            ))}
            
            {/* Charging station information */}
            {isCharging && dataPoint?.stationName && (
                <div style={{ 
                    marginTop: '8px', 
                    borderTop: '1px solid #eee', 
                    paddingTop: '5px',
                    color: '#3a7ca5' 
                }}>
                    <p style={{ margin: '2px 0' }}>
                        <strong>Charging at:</strong> {dataPoint.stationName}
                    </p>
                    {dataPoint.stationType && (
                        <p style={{ margin: '2px 0' }}>
                            <strong>Station type:</strong> {dataPoint.stationType === 'depot' ? 'Depot' : 'Public'}
                        </p>
                    )}
                </div>
            )}
            
            {/* Charging trucks information */}
            {dataPoint?.chargingTrucks && dataPoint.chargingTrucks.length > 0 && (
                <div style={{ 
                    marginTop: '8px', 
                    borderTop: '1px solid #eee', 
                    paddingTop: '5px'
                }}>
                    <p style={{ margin: '2px 0', fontWeight: 'bold' }}>
                        Charging Trucks (Total: {dataPoint.chargingTrucks.length}):
                    </p>
                    {dataPoint.chargingTrucks.map((truck, idx) => (
                        <div key={idx} style={{ 
                            margin: '5px 0',
                            padding: '3px 0',
                            borderBottom: idx < dataPoint.chargingTrucks.length - 1 ? '1px dotted #eee' : 'none'
                        }}>
                            <p style={{ 
                                margin: '2px 0',
                                color: '#2c5f7c',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px'
                            }}>
                                <strong style={{
                                    backgroundColor: '#e8f4f9',
                                    padding: '2px 6px',
                                    borderRadius: '3px',
                                    color: '#2c5f7c'
                                }}>{idx+1}</strong>
                                <span>
                                    <strong>Model:</strong> {truck.truck_model} • <strong>Route:</strong> {truck.route_name}
                                </span>
                            </p>
                            <p style={{ 
                                margin: '2px 0', 
                                fontSize: '12px',
                                display: 'flex',
                                gap: '8px'
                            }}>
                                <span>
                                    <strong>{truck.charging_power ? 'Actual Power:' : 'Power:'}</strong> {parseFloat(truck.charging_power || truck.charging_power).toFixed(1)} kW
                                </span>
                                {truck.charger_type && (
                                    <span>
                                        <strong>Charger Spec:</strong> {truck.charger_type} kW
                                    </span>
                                )}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};