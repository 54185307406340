import React, { useState, useEffect } from "react";
import "./css/RunHistoryTable.css";
import axios from "axios";
import { API_ENDPOINTS } from "../../../config/apiConfig";

export const RunHistoryTable = ({ onViewCase }) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const authToken = localStorage.getItem("authToken");



  useEffect(() => {
    setLoading(true);
    axios
      .get(API_ENDPOINTS.getAllOptimizations, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          const sortedData = response.data.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );
          setTableData(sortedData);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authToken]);

  const handleViewCase = (caseId) => {
    setLoading(true);
    axios
      .get(API_ENDPOINTS.getOptimization, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
        params: {
          case_id: caseId,
        },
      })
      .then((response) => {
        if (response.data && typeof response.data === 'object') {
          onViewCase(response.data);
        } else {
          console.error('Invalid response data format:', response.data);
          alert('Error: Received invalid data format from server');
        }
      })
      .catch((error) => {
        console.log('Case ID that failed:', caseId);
        console.error('Error fetching optimization case:', error.message);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error response data:', error.response.data);
          console.error('Error response status:', error.response.status);
          console.error('Error response headers:', error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error config:', error.config);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading && !tableData.length) {
    return <p>Loading...</p>;
  }

  // Define columns with widths
  const columns = [
      { key: 'checkbox', label: '', width: '5%' },
      { key: 'name', label: 'Name', width: '40%' },
      { key: 'timestamp', label: 'Timestamp', width: '40%' },
      { key: 'action', label: 'Action', width: '15%' }
  ];
  
  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr className="table-header">
            <th style={{ width: columns[0].width }}></th>
            <th style={{ width: columns[1].width }}>Name</th>
            <th style={{ width: columns[2].width }}>Timestamp</th>
            <th style={{ width: columns[3].width }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((optimization, index) => (
            <tr
              key={`${optimization.case_name}-${index}`}
            >
              <td style={{ width: columns[0].width }}>
                <input
                  type="checkbox"
                  className="charger-checkbox"
                />
              </td>
              <td style={{ width: columns[1].width }}>{optimization.case_name}</td>
              <td style={{ width: columns[2].width }}>{new Date(optimization.timestamp).toLocaleString()}</td>
              <td style={{ width: columns[3].width }}>
                <button 
                  className="view-button"
                  onClick={() => handleViewCase(optimization.id)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && <div className="loading-overlay">Loading...</div>}
    </div>
  );
};
