import "../../App.css";
import "./css/myOperations.css"
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { CustomMap } from "../../components/pro_calculator/CustomMap";
import { RoutingTable } from "../../components/pro_calculator/tables/RoutingTable";
import { ChargerTable } from "../../components/pro_calculator/tables/ChargerTable";
import { DepotTable } from "../../components/pro_calculator/tables/DepotTable";
import { AddButton } from "../../components/general/addButton";
import { DeleteButton } from "../../components/general/deleteButton";
import { API_ENDPOINTS } from '../../config/apiConfig';
import { Notification } from "../../components/general/Notification";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);


function MyOperations() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedChargers, setSelectedChargers] = useState({});
  const [selectTable, setSelectTable] = useState("route");
  const [lastMapSelection, setLastMapSelection] = useState(null);
  const [selectedRoutes, setSelectedRoutes] = useState({});
  const [coordinates, setCoordinates] = useState([]); 
  const [processedRoutes, setProcessedRoutes] = useState([]); 
  const [depots, setDepots] = useState([]);
  const [selectedDepots, setSelectedDepots] = useState({});
  const [showChargers, setShowChargers] = useState(true);
  const [showDepots, setShowDepots] = useState(false);
  const [circuitData, setCircuitData] = useState({});
  const [notification, setNotification] = useState({
    message: "",
    type: "info",
    visible: false
  });
  const [rawRouteData, setRawRouteData] = useState({});
  const [loadingRoutes, setLoadingRoutes] = useState({});
  const [mapRefreshTrigger, setMapRefreshTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  const authToken = localStorage.getItem("authToken");

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(API_ENDPOINTS.user, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  useEffect(() => {
    axios
      .get(API_ENDPOINTS.getProcessedRoutes, {
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setProcessedRoutes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching processedRoutes", error);
      });
  }, [authToken]);

  useEffect(() => {
    if (authToken) {
      // fetchRoutes();
      fetchDepots();
      // fetchChargers();
    }
  }, [authToken]);

  const handleSelectTable = (e) => {
    e.preventDefault();
    const value = e.currentTarget.value;
    setSelectTable(value);
  }

  const handleChargerSelect = (chargerId, fromMap = false) => {
    // Check if we received an object (batch selection)
    if (typeof chargerId === 'object') {
      // This is a select all operation
      setSelectedChargers(chargerId);
    } else {
      // This is a single charger selection
      const isSelecting = !selectedChargers[chargerId];
      
      setSelectedChargers((prev) => ({
        ...prev,
        [chargerId]: isSelecting,
      }));
      
      if (fromMap && isSelecting) {
        setLastMapSelection(chargerId);
        
        // Automatically switch to charger tab when selecting from map
        setSelectTable("charger");
      }
    }
  };

  const handleRouteSelect = async (routeId, fromView = false) => {
    const isSelecting = fromView ? true : !selectedRoutes[routeId];

    if (fromView && selectedRoutes[routeId]) {
      return;
    }

    setSelectedRoutes((prev) => ({
      ...prev,
      [routeId]: isSelecting,
    }));

    if (fromView) {
      setLastMapSelection(routeId);
      setSelectTable("route"); // Switch to route tab when selecting from map
    }

    if (isSelecting) {
      try {
        const selectedRoute = processedRoutes.find((route) => route.id === routeId);
        if (!selectedRoute) {
          console.error("Route not found in processedRoutes list");
          return;
        }

        const response = await axios.get(API_ENDPOINTS.getRawRoute, {
          params: { route_id: selectedRoute.id },
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });

        if (
          response.data &&
          response.data.links &&
          response.data.links[0] &&
          response.data.links[0].coordinates
        ) {
          const routeCoordinates = response.data.links[0].coordinates.map(([lon, lat]) => ({
            lat: lat,
            lon: lon,
          }));
          setCoordinates(routeCoordinates);
        } else {
          console.error("Unexpected data structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching raw route data:", error);
      }
    }
  };

  // Field definitions
  const route_fields = [
    { name: "Route name", type: "string", id: "route_name" },
    { name: "Origin", type: "address", id: "origin" },
    { name: "Departure time", type: "time", id: "start_time" }
  ];

  const depot_fields = [
    { name: "Depot Name", type: "string", id: "depot_name" },
    { name: "Address", type: "address", id: "address" },
    //{ name: "Area", type: "number", id: "area" },
    //{ name: "Utility", type: "string", id: "utility" },
    //{ name: "Power Capacity", type: "number", id: "power_capacity" },
  ];

  const charger_fields = [
    { name: "Charging Station Name", type: "string", id: "charger-name" },
  ];

  // Handlers
  const handleAddRoute = async (formData) => {
    try {
      // Create a new array with the origin as the first stop
      const stops = [formData.origin];
      
      // Add any additional stops from the formData.stops array
      if (formData.stops && formData.stops.length > 0) {
        stops.push(...formData.stops);
      }

      const routeData = {
        start_time: formData.start_time,
        stops: stops,
        route_name: formData.route_name,
        idle_times: formData.idle_times || [], // Ensure idle_times is always defined
      };


      const response = await axios.post(
        API_ENDPOINTS.addRoute,
        routeData,
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      // If successful, fetch the updated processedRoutes list
      if (response.status === 200 || response.status === 201) {
        // Fetch updated processedRoutes
        const routesResponse = await axios.get(API_ENDPOINTS.getProcessedRoutes, {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });
        
        // Update processedRoutes state with the fresh data
        setProcessedRoutes(routesResponse.data);
        
        // Show success notification
        setNotification({
          message: "Route added successfully!",
          type: "success",
          visible: true
        });
        
        // Hide notification after 3 seconds
        setTimeout(() => {
          setNotification(prev => ({...prev, visible: false}));
        }, 3000);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error ||
        error.response?.data?.message ||
        "Error adding route";
      console.error("Error adding route:", error);
      
      // Show error notification
      setNotification({
        message: errorMessage,
        type: "error",
        visible: true
      });
      
      // Hide notification after 3 seconds
      setTimeout(() => {
        setNotification(prev => ({...prev, visible: false}));
      }, 3000);
    }
  };

  const handleAddDepot = async (formData) => {
    try {
      const depotData = {
        name: formData.depot_name,
        address: formData.address,
        //area: parseFloat(formData.area),
        //utility: formData.utility,
        //power_capacity: parseFloat(formData.power_capacity)
      };


      const response = await axios.post(
        API_ENDPOINTS.addDepot,
        depotData,
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      // Update the depots state with the new depot
      setDepots(prevDepots => [...prevDepots, response.data]);
      
      // Increment the refresh trigger to cause the map to refetch depots
      setMapRefreshTrigger(prev => prev + 1);
      
      setNotification({
        message: "Depot added successfully!",
        type: "success",
        visible: true
      });
      
      // Hide notification after 3 seconds
      setTimeout(() => {
        setNotification(prev => ({ ...prev, visible: false }));
      }, 3000);
      
      return response.data;
    } catch (error) {
      console.error("Error adding depot:", error);
      setNotification({
        message: "Failed to add depot. Please try again.",
        type: "error",
        visible: true
      });
      
      // Hide notification after 3 seconds
      setTimeout(() => {
        setNotification(prev => ({ ...prev, visible: false }));
      }, 3000);
      
      throw error;
    }
  };

  const fetchDepots = async () => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.getDepot,
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      
      
      if (response.data) {
        // Make sure we're setting the correct data structure
        // This depends on your API response format
        const depotsData = Array.isArray(response.data) ? response.data : 
                           response.data.depots ? response.data.depots : [];
        
        setDepots(depotsData);
      }
    } catch (error) {
      console.error("Error fetching depots:", error);
      setNotification({
        message: "Failed to load depots: " + (error.message || "Unknown error"),
        type: "error",
        visible: true
      });
      
      setTimeout(() => {
        setNotification(prev => ({...prev, visible: false}));
      }, 3000);
    }
  };

  const fetchCircuitData = async (depot) => {
    try {
      const response = await axios.post(
        API_ENDPOINTS.getCircuits,
        { circuit_names: depot.circuits },
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setCircuitData(prev => ({
        ...prev,
        [depot.depot_id]: response.data.circuits
      }));
    } catch (error) {
      console.error("Error fetching circuit data:", error);
    }
  };

  const handleDepotSelect = async (depot_id, fromMap = false) => {
    // Check if we received an object (batch selection)
    if (typeof depot_id === 'object') {
      // This is a select all operation
      setSelectedDepots(depot_id);
      
      // Fetch circuit data for newly selected depots
      for (const [id, isSelected] of Object.entries(depot_id)) {
        if (isSelected) {
          const depot = depots.find(d => d.depot_id === id);
          if (depot && depot.circuits && depot.circuits.length > 0) {
            await fetchCircuitData(depot);
          }
        }
      }
    } else {
      // This is a single depot selection
      const isSelecting = !selectedDepots[depot_id];
      setSelectedDepots((prev) => ({
        ...prev,
        [depot_id]: isSelecting,
      }));
      
      if (isSelecting) {
        const depot = depots.find(d => d.depot_id === depot_id);
        if (depot && depot.circuits && depot.circuits.length > 0) {
          await fetchCircuitData(depot);
        }
      }
      
      if (fromMap && isSelecting) {
        setLastMapSelection(depot_id);
        setSelectTable("depot"); // Switch to depot tab when selecting from map
      }
    }
  };

  const handleShowChargers = (checked) => {
    setShowChargers(checked);
  }

  const fetchAllRawRoutes = async () => {
    if (Object.keys(rawRouteData).length > 0) return; // Skip if we already have data
    
    try {
      setIsLoading(true); // Add a global loading state if you have one
      
      const response = await axios.get(API_ENDPOINTS.getAllRawRoutes, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      
      // Process the response which should contain all routes
      const allRoutesData = response.data;
      // Convert the array of routes to an object with routeId as keys
      const routesObject = {};
      allRoutesData.forEach(route => {
        routesObject[route.id] = route;
      });
      
      // Update state with all routes at once
      setRawRouteData(routesObject);
      
    } catch (error) {
      console.error("Error fetching all raw route data:", error);
    } finally {
      setIsLoading(false); // Clear loading state
    }
  };

  // Rendering table
  const renderTable = () => {
    switch (selectTable) {
      case "route":
        return (
          <RoutingTable 
            selectedRoutes={selectedRoutes}
            onRouteSelect={handleRouteSelect}
            lastMapSelection={lastMapSelection}
            tableData={processedRoutes}
            selectTable={selectTable}
            handleSelectTable={handleSelectTable}
            rawRouteData={rawRouteData}
            fetchAllRawRoutes={fetchAllRawRoutes}
          />
        );
      case "depot":
        return (
          <DepotTable 
            selectedDepots={selectedDepots}
            onDepotSelect={handleDepotSelect}
            lastMapSelection={lastMapSelection}
            tableData={depots}
            selectTable={selectTable}
            handleSelectTable={handleSelectTable}
          />
        );
      case "charger":
        return (
          <ChargerTable 
            selectedChargers={selectedChargers}
            onChargerSelect={handleChargerSelect}
            lastMapSelection={lastMapSelection}
            selectTable={selectTable}
            handleSelectTable={handleSelectTable}
          />
        );
      default:
        return null;
    }
  };

  // Add a function to handle deleting selected depots
  const handleDeleteSelectedDepots = async () => {
    // Get the IDs of selected depots
    const selectedDepotIds = Object.entries(selectedDepots)
      .filter(([_, selected]) => selected)
      .map(([id, _]) => id);
    
    if (selectedDepotIds.length === 0) {
      setNotification({
        message: "No depots selected for deletion",
        type: "info",
        visible: true
      });
      
      setTimeout(() => {
        setNotification(prev => ({...prev, visible: false}));
      }, 3000);
      
      return;
    }
    
    try {
      // Send a POST request with the depot_ids in the request body
      const response = await axios.post(
        API_ENDPOINTS.deleteDepot,
        { depot_ids: selectedDepotIds },
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        // Update the depots state by filtering out the deleted depots
        setDepots(prevDepots => 
          prevDepots.filter(depot => !selectedDepotIds.includes(depot.depot_id))
        );
        
        // Clear the selected depots
        setSelectedDepots({});
        
        // Show success notification
        setNotification({
          message: `${selectedDepotIds.length} depot(s) deleted successfully!`,
          type: "success",
          visible: true
        });
        
        setTimeout(() => {
          setNotification(prev => ({...prev, visible: false}));
        }, 3000);
      } else {
        throw new Error("Unexpected response status: " + response.status);
      }
    } catch (error) {
      console.error("Error deleting depots:", error);
      
      const errorMessage = error.response?.data?.error ||
        error.response?.data?.message ||
        error.message ||
        "Error deleting depots";
      
      // Show error notification
      setNotification({
        message: errorMessage,
        type: "error",
        visible: true
      });
      
      setTimeout(() => {
        setNotification(prev => ({...prev, visible: false}));
      }, 3000);
    }
  };

  // Add a function to handle deleting selected routes
  const handleDeleteSelectedRoutes = async () => {
    // Get the IDs of selected routes
    const selectedRouteIds = Object.entries(selectedRoutes)
      .filter(([_, selected]) => selected)
      .map(([id, _]) => id);
    
    if (selectedRouteIds.length === 0) {
      setNotification({
        message: "No routes selected for deletion",
        type: "info",
        visible: true
      });
      
      setTimeout(() => {
        setNotification(prev => ({...prev, visible: false}));
      }, 3000);
      
      return;
    }
    
    try {
      // Send a POST request with the route_ids in the request body
      const response = await axios.post(
        API_ENDPOINTS.deleteRoute,
        { route_ids: selectedRouteIds },
        {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
       
      if (response.status >= 200 && response.status < 300) {
        // Update the routes state by filtering out the deleted routes
        setProcessedRoutes(prevRoutes => 
          prevRoutes.filter(route => !selectedRouteIds.includes(route.id))
        );
        
        // Clear the selected routes
        setSelectedRoutes({});
        
        // Show success notification
        setNotification({
          message: `${selectedRouteIds.length} route(s) deleted successfully!`,
          type: "success",
          visible: true
        });
        
        setTimeout(() => {
          setNotification(prev => ({...prev, visible: false}));
        }, 3000);
      } else {
        throw new Error("Unexpected response status: " + response.status);
      }
    } catch (error) {
      console.error("Error deleting routes:", error);
      
      const errorMessage = error.response?.data?.error ||
        error.response?.data?.message ||
        error.message ||
        "Error deleting routes";
      
      // Show error notification
      setNotification({
        message: errorMessage,
        type: "error",
        visible: true
      });
      
      setTimeout(() => {
        setNotification(prev => ({...prev, visible: false}));
      }, 3000);
    }
  };

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <div className="container-flex-row">
          <h1 className="title-label">My Operations</h1>
          <div className="my-operation-actions">
            <AddButton 
              fields={route_fields}
              entity="Route"
              onSubmit={handleAddRoute}
              text="Add Route"
              className="add-route-btn"
              dynamicAddRoute={true}
            />
            {selectTable === "route" && Object.keys(selectedRoutes).some(id => selectedRoutes[id]) && (
              <DeleteButton 
                entity="Route"
                onDelete={handleDeleteSelectedRoutes}
                selectedItems={processedRoutes.filter(route => selectedRoutes[route.id])}
                position="right"
              />
            )}
            <AddButton 
              fields={depot_fields}
              entity="Depot"
              onSubmit={handleAddDepot}
              text="Add Depot"
              className="add-depot-btn"
            />
            {/* <AddButton 
              fields={charger_fields}
              entity="Charger"
              onClick={handleAddCharger}
              text="Add Charger"
              className="add-charger-btn"
            /> */}
            {selectTable === "depot" && Object.keys(selectedDepots).some(id => selectedDepots[id]) && (
              <DeleteButton 
                entity="Depot"
                onDelete={handleDeleteSelectedDepots}
                selectedItems={depots.filter(depot => selectedDepots[depot.depot_id])}
                position="right"
              />
            )}
          </div>
        </div>
        <div className="map-dashboard-container map-container-top">
          <CustomMap
            selectedRoutes={selectedRoutes}
            selectedEntities={selectedDepots}
            selectedChargers={selectedChargers}
            circuitData={circuitData}
            onMarkerClick={handleDepotSelect}
            onChargerClick={handleChargerSelect}
            highlightedRoute={coordinates && coordinates.length > 0}
            depotList={depots}
            refreshTrigger={mapRefreshTrigger}
          />
        </div>
        <div className="dashboard-container table-container-bottom">
          {renderTable()}
        </div>
        <Notification 
          message={notification.message}
          type={notification.type}
          visible={notification.visible}
        />
      </div>
    </div>
  );
}

export default MyOperations;
