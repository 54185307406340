import React, { useState, useEffect } from "react";
import SelectionTable from "./common/SelectionTable";

const TruckSelection = ({ 
  trucks,
  loading,
  selectedTrucks, 
  setSelectedTrucks,
  truckCustomData,
  setTruckCustomData,
  nextStep, 
  prevStep 
}) => {
  const [editedIncentives, setEditedIncentives] = useState({});
  const [editedPrices, setEditedPrices] = useState({});

  // Initialize or update truckCustomData when trucks or edited values change
  useEffect(() => {
    if (trucks.length > 0) {
      const updatedCustomData = { ...truckCustomData };
      
      // Update with any edited values
      trucks.forEach(truck => {
        const truckId = truck.truck_id;
        
        if (!updatedCustomData[truckId]) {
          updatedCustomData[truckId] = {
            price: truck.price,
            incentives: truck.incentives
          };
        }
        
        if (editedPrices.hasOwnProperty(truckId)) {
          updatedCustomData[truckId].price = editedPrices[truckId];
        }
        
        if (editedIncentives.hasOwnProperty(truckId)) {
          updatedCustomData[truckId].incentives = editedIncentives[truckId];
        }
      });
      
      setTruckCustomData(updatedCustomData);
    }
  }, [trucks, editedPrices, editedIncentives]);

  // Handle checkbox change
  const handleCheckboxChange = (truckIdOrArray, e) => {
    // Stop propagation if event is provided
    if (e) {
      e.stopPropagation();
    }
    
    setSelectedTrucks((prevSelected) => {
      // Handle array of IDs (for select all/none functionality)
      if (Array.isArray(truckIdOrArray)) {
        return truckIdOrArray;
      }
      // Handle single ID toggle
      const truckId = truckIdOrArray;
      if (prevSelected.includes(truckId)) {
        return prevSelected.filter((id) => id !== truckId);
      } else {
        return [...prevSelected, truckId];
      }
    });
  };

  // Handle "Select All" checkbox
  const handleSelectAll = (e) => {
    if (e) e.stopPropagation();
    if (allSelected) {
      // Deselect all trucks
      handleCheckboxChange([]);
    } else {
      // Select all trucks at once
      handleCheckboxChange(trucks.map(truck => truck.truck_id));
    }
  };

  // Incentive handling
  const handleIncentiveChange = (truckId, value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    const incentiveValue = numericValue === '' ? 0 : parseInt(numericValue, 10);

    setEditedIncentives(prev => ({
      ...prev,
      [truckId]: incentiveValue
    }));
  };

  const getIncentiveValue = (truck) => {
    return editedIncentives.hasOwnProperty(truck.truck_id)
      ? editedIncentives[truck.truck_id]
      : truckCustomData[truck.truck_id]?.incentives || truck.incentives;
  };

  // Price handling
  const handlePriceChange = (truckId, value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    const priceValue = numericValue === '' ? 0 : parseInt(numericValue, 10);

    setEditedPrices(prev => ({
      ...prev,
      [truckId]: priceValue
    }));
  };

  const getPriceValue = (truck) => {
    return editedPrices.hasOwnProperty(truck.truck_id)
      ? editedPrices[truck.truck_id]
      : truckCustomData[truck.truck_id]?.price || truck.price;
  };

  // Format currency with thousands separator and no decimals
  const formatCurrency = (value) => {
    return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Determine if all trucks are selected
  const allSelected = trucks.length > 0 && selectedTrucks.length === trucks.length;

  // Define columns for the truck table
  const truckColumns = [
    { key: 'make', label: 'Make', width: '12.5%' },
    { key: 'model', label: 'Model', width: '12.5%' },
    { key: 'batt_cap', label: 'Battery (kWh)', width: '12.5%' },
    { key: 'operation_range', label: 'Range (miles)', width: '12.5%' },
    { key: 'price', label: 'MSRP', width: '15%' },
    { key: 'incentives', label: 'Incentives', width: '15%' },
    { key: 'is_available', label: 'Available', width: '15%' }
  ];

  // Define how to render each row
  const renderTruckRow = (truck) => (
    <>
      <td style={{ width: truckColumns[0].width }}>{truck.make}</td>
      <td style={{ width: truckColumns[1].width }}>{truck.model}</td>
      <td style={{ width: truckColumns[2].width }}>{truck.batt_cap}</td>
      <td style={{ width: truckColumns[3].width }}>{truck.operation_range.toFixed(2)}</td>
      <td style={{ width: truckColumns[4].width }} onClick={(e) => e.stopPropagation()}>
        <div className="editable-cell" style={{ paddingRight: '20%' }}>
          <span className="currency-symbol">$</span>
          <input
            type="text"
            value={formatCurrency(getPriceValue(truck))}
            onChange={(e) => handlePriceChange(truck.truck_id, e.target.value)}
            className="incentive-input"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </td>
      <td style={{ width: truckColumns[5].width }} onClick={(e) => e.stopPropagation()}>
        <div className="editable-cell" style={{ paddingRight: '20%' }}>
          <span className="currency-symbol">$</span>
          <input
            type="text"
            value={formatCurrency(getIncentiveValue(truck))}
            onChange={(e) => handleIncentiveChange(truck.truck_id, e.target.value)}
            className="incentive-input"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </td>
      <td style={{ width: truckColumns[6].width }}>
        {truck.is_available ? "Yes" : "No"}
      </td>
    </>
  );

  if (loading) {
    return <div className="step-container"><p>Loading trucks data...</p></div>;
  }

  return (
    <div className="step-container">
      <h2>Step 4: Select Electric Trucks</h2>
      <p>Choose the electric vehicles you want to consider for your fleet.</p>

      {trucks.length === 0 ? (
        <p>No trucks found. Please add trucks to continue.</p>
      ) : (
        <SelectionTable
          items={trucks}
          selectedItems={selectedTrucks}
          handleCheckboxChange={handleCheckboxChange}
          handleSelectAll={handleSelectAll}
          allSelected={allSelected}
          columns={truckColumns}
          itemIdKey="truck_id"
          renderRow={renderTruckRow}
        />
      )}

      <div className="step-actions">
        <button className="back-btn" onClick={prevStep}>
          Back
        </button>
        <button
          className="next-btn"
          onClick={nextStep}
          disabled={selectedTrucks.length === 0}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TruckSelection; 