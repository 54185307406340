import React, { useState, useEffect, useRef } from "react";
import "./css/ChargerTable.css";
import "./css/TableCommon.css";
import axios from "axios";
import { CustomMap } from "../CustomMap";
import { API_ENDPOINTS } from "../../../config/apiConfig";

export const ChargerTable = ({
  selectedChargers,
  onChargerSelect,
  lastMapSelection,
  selectTable,
  handleSelectTable
}) => {
  const [tableData, setTableData] = useState([]);
  const [highlightedRow, setHighlightedRow] = useState(null);
  const authToken = localStorage.getItem("authToken");
  const rowRefs = useRef({});
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const scrollToRow = (cs_id) => {
    if (rowRefs.current[cs_id]) {
      setHighlightedRow(cs_id);
      const tableBody = document.querySelector(".table tbody");
      const rowElement = rowRefs.current[cs_id];
      const rowRect = rowElement.getBoundingClientRect();
      const containerRect = tableBody.getBoundingClientRect();

      const scrollTop =
        tableBody.scrollTop +
        (rowRect.top - containerRect.top) -
        containerRect.height / 2 +
        rowRect.height / 2;

      tableBody.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });

      setTimeout(() => {
        setHighlightedRow(null);
      }, 500);
    }
  };

  useEffect(() => {
    if (lastMapSelection && rowRefs.current[lastMapSelection]) {
      scrollToRow(lastMapSelection);
    }
  }, [lastMapSelection]);

  useEffect(() => {
    axios
      .get(API_ENDPOINTS.getCharger, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setTableData(response.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  console.log(tableData);

  const handleViewDetails = (charger) => {
    setModalData(charger);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    
    const updatedSelection = {};
    
    if (newSelectAll) {
      tableData.forEach(charger => {
        if (charger.cs_id) {
          updatedSelection[charger.cs_id] = true;
        }
      });
    }
    
    onChargerSelect(updatedSelection, true);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      const allSelected = tableData.every(charger => 
        charger.cs_id && selectedChargers[charger.cs_id]
      );
      setSelectAll(allSelected);
    }
  }, [selectedChargers, tableData]);

  if (!tableData) {
    return <p>Loading ...</p>;
  }

  return (
    <>
      <div className="table-header-container">
        <div className="table-toggle-buttons">
          <button 
            className={`table-select-button ${selectTable === "route" ? "selected" : ""}`} 
            value="route" 
            onClick={(e) => {
              e.preventDefault();
              handleSelectTable(e);
            }}
            type="button"
          >
            <span className={`table-select-button-text ${selectTable === "route" ? "selected-text" : ""}`}>
              Routes
            </span>
          </button>
          <button 
            className={`table-select-button ${selectTable === "depot" ? "selected" : ""}`} 
            value="depot" 
            onClick={(e) => {
              e.preventDefault();
              handleSelectTable(e);
            }}
            type="button"
          >
            <span className={`table-select-button-text ${selectTable === "depot" ? "selected-text" : ""}`}>
              Depots
            </span>
          </button>
          <button 
            className={`table-select-button ${selectTable === "charger" ? "selected" : ""}`} 
            value="charger" 
            onClick={(e) => {
              e.preventDefault();
              handleSelectTable(e);
            }}
            type="button"
          >
            <span className={`table-select-button-text ${selectTable === "charger" ? "selected-text" : ""}`}>
              Public Chargers
            </span>
          </button>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr className="table-header">
            <th style={{ width: '5%' }}>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
                className="charger-checkbox select-all-checkbox"
              />
            </th>
            {/* <th style={{ width: '5%' }}>ID</th> */}
            <th style={{ width: '20%' }}>Name</th>
            <th style={{ width: '15%' }}>Owner</th>
            <th style={{ width: '20%' }}>Address</th>
            <th style={{ width: '10%' }}>Status</th>
            <th style={{ width: '10%' }}>Max Power</th>
            <th style={{ width: '10%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((charger, index) => (
            <tr
              ref={(el) => (rowRefs.current[charger.cs_id] = el)}
              className={`table-content ${
                selectedChargers[charger.cs_id] ? "selected" : ""
              } ${highlightedRow === charger.cs_id ? "highlighted" : ""}`}
              key={`${charger.cs_id || charger.name || 'unnamed'}-${index}`}
              onClick={() => onChargerSelect(charger.cs_id)}
              style={{ cursor: 'pointer' }}
            >
              <td style={{ width: '5%' }}>
                <input
                  type="checkbox"
                  checked={selectedChargers[charger.cs_id] || false}
                  onChange={(e) => {
                    e.stopPropagation(); // Prevent row click when clicking checkbox
                    onChargerSelect(charger.cs_id);
                  }}
                  className="charger-checkbox"
                />
              </td>
              {/* <td style={{ width: '5%' }}>{charger.cs_id}</td> */}
              <td style={{ width: '20%' }}>{charger.name || 'N/A'}</td>
              <td style={{ width: '15%' }}>{charger.owner || 'N/A'}</td>
              <td style={{ width: '20%' }}>{charger.addr || 'N/A'}</td>
              <td style={{ width: '10%' }}>{charger.status || 'N/A'}</td>
              <td style={{ width: '10%' }}>{charger.max_power ? `${charger.max_power} kW` : 'N/A'}</td>
              <td style={{ width: '10%' }}>
                <button 
                  className="view-button"
                  onClick={() => handleViewDetails(charger)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && modalData && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{modalData.name || 'Unnamed Charging Station'}</h2>
              <button className="close-button" onClick={closeModal}>×</button>
            </div>
            <div className="modal-body">
              <div className="modal-section">
                <h3>Basic Information</h3>
                {/* <p><strong>ID:</strong> {modalData.cs_id}</p> */}
                <p><strong>Owner:</strong> {modalData.owner || 'N/A'}</p>
                <p><strong>Address:</strong> {modalData.addr || 'N/A'}</p>
                <p><strong>Coordinates:</strong> {modalData.lat && modalData.lon ? `${modalData.lat}, ${modalData.lon}` : 'N/A'}</p>
                <p><strong>Status:</strong> {modalData.status || 'N/A'}</p>
                <p><strong>Year of Operation:</strong> {modalData.year_op || 'N/A'}</p>
              </div>

              <div className="modal-section">
                <h3>Charging Details</h3>
                <p><strong>Chargers:</strong> {modalData.chargers ? modalData.chargers.join(', ') : 'N/A'}</p>
                <p><strong>Power Ratings:</strong> {modalData.powers ? modalData.powers.map(p => `${p} kW`).join(', ') : 'N/A'}</p>
                <p><strong>Max Power:</strong> {modalData.max_power ? `${modalData.max_power} kW` : 'N/A'}</p>
                <p><strong>Stall Count:</strong> {modalData.stall_count || 'N/A'}</p>
                <p><strong>Plug Count:</strong> {modalData.plug_count || 'N/A'}</p>
              </div>

              <div className="modal-section">
                <h3>Access Information</h3>
                <p><strong>Type of Access:</strong> {modalData.type_of_access || 'N/A'}</p>
                <p><strong>Facility Type:</strong> {modalData.facility_type || 'N/A'}</p>
                <p><strong>Eligible Vehicles:</strong> {modalData.eligible_vehicles || 'N/A'}</p>
                <p><strong>EV Network:</strong> {modalData.ev_network || 'N/A'}</p>
                <p><strong>Vehicle Classes:</strong> {modalData.vehicle_classes || 'N/A'}</p>
                <p><strong>Pull Through:</strong> {modalData.pullthrough || 'N/A'}</p>
                <p><strong>Domicile at Site:</strong> {modalData.domicile_at_site || 'N/A'}</p>
              </div>

              <div className="modal-section">
                <h3>Payment and Contact</h3>
                <p><strong>Accepted Payment:</strong> {modalData.accepted_payment || 'N/A'}</p>
                <p><strong>Contact Name:</strong> {modalData.contact_name || 'N/A'}</p>
                <p><strong>Contact Email:</strong> {modalData.contact_email || 'N/A'}</p>
                <p><strong>Facility Phone:</strong> {modalData.facility_phone || 'N/A'}</p>
              </div>

              <div className="modal-section">
                <h3>Additional Information</h3>
                <p><strong>Hours of Operation:</strong> {modalData.hours_of_operation || 'N/A'}</p>
                <p><strong>Fuel Type:</strong> {modalData.fuel_type || 'N/A'}</p>
                <p><strong>Notes:</strong> {modalData.notes || 'N/A'}</p>
                <p><strong>Additional Info:</strong> {
                  modalData.additional_info ? 
                    (modalData.additional_info.includes('http') ? 
                      <a href={modalData.additional_info} target="_blank" rel="noopener noreferrer">
                        {modalData.additional_info}
                      </a> : 
                      modalData.additional_info) : 
                    'N/A'
                }</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
