import React from "react";
import LineChartComponent from "../LineChart";

// Add initialEnergyChargeData here
const initialEnergyChargeData = [
  { x: 0, y: 0.17133 },
  { x: 1, y: 0.17133 },
  { x: 2, y: 0.17133 },
  { x: 3, y: 0.17133 },
  { x: 4, y: 0.17133 },
  { x: 5, y: 0.17133 },
  { x: 6, y: 0.17133 },
  { x: 7, y: 0.17133 },
  { x: 8, y: 0.17133 },
  { x: 9, y: 0.17133 },
  { x: 10, y: 0.17133 },
  { x: 11, y: 0.17133 },
  { x: 12, y: 0.17133 },
  { x: 13, y: 0.17133 },
  { x: 14, y: 0.17133 },
  { x: 15, y: 0.17133 },
  { x: 16, y: 0.46034 },
  { x: 17, y: 0.46034 },
  { x: 18, y: 0.46034 },
  { x: 19, y: 0.46034 },
  { x: 20, y: 0.46034 },
  { x: 21, y: 0.17133 },
  { x: 22, y: 0.17133 },
  { x: 23, y: 0.17133 },
];

// Add TOU preprocessing function
export const expandEnergyChargeData = (data) => {
  // Maps 1-hour increment to 15-minute increment
  return data.reduce((result, { x, y }) => {
    for (let i = 0; i < 4; i++) {
      result[x * 4 + i] = y; // Add key-value pair to the result
    }
    return result;
  }, {});
};

const TOUSettings = ({ energyChargeData, setEnergyChargeData, nextStep, prevStep }) => {
  // Remove local state since we're using the parent's state directly
  // const [energyChargeData, setEnergyChargeData] = useState(initialEnergyChargeData);

  // Initialize energy charge data if empty
  React.useEffect(() => {
    if (energyChargeData.length === 0) {
      setEnergyChargeData(initialEnergyChargeData);
    }
  }, [energyChargeData, setEnergyChargeData]);

  return (
    <div className="step-container">
      <h2>Step 5: Time-of-Use Energy Rates</h2>
      <p>Set the electricity rates for different times of the day. You can download the CSV template, make adjustments, and then upload it again.</p>
      <p>Example shows SCE's EV-9 Schedule for 2kV-50kV customers.</p>

      <div className="tou-chart-container">
        <LineChartComponent
          xLabel="Time"
          yLabel="Energy Charge ($/kWh)"
          initialData={energyChargeData.length > 0 ? energyChargeData : initialEnergyChargeData}
          onDataUpdate={setEnergyChargeData} 
        />
      </div>

      <div className="step-actions">
        <button className="back-btn" onClick={prevStep}>
          Back
        </button>
        <button className="next-btn" onClick={nextStep}>
          Next
        </button>
      </div>
    </div>
  );
};

export default TOUSettings; 