import React, { useEffect } from 'react';
import './css/TableCommon.css';
import './css/RoutingTable.css';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../config/apiConfig';

export const RoutingTable = ({
  selectedRoutes,
  onRouteSelect,
  lastMapSelection,
  tableData,
  selectTable,
  handleSelectTable,
  rawRouteData,
  fetchAllRawRoutes
}) => {
  // Fetch raw data for visible routes when component mounts
  useEffect(() => {
    // Fetch all raw route data when the component mounts
    fetchAllRawRoutes();
  }, []);

  console.log("rawRouteData", rawRouteData);

  // Format distance to miles with 1 decimal place
  const formatDistance = (meters) => {
    if (!meters && meters !== 0) return "Loading...";
    const miles = meters / 1609.34; // Convert meters to miles
    return `${miles.toFixed(1)} mi`;
  };

  // Format time from seconds to hours and minutes
  const formatTime = (seconds) => {
    if (!seconds && seconds !== 0) return "Loading...";
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
  };

  return (
    <>
      <div className="table-header-container">
        <div className="table-toggle-buttons">
          <button
            className={`table-select-button ${selectTable === "route" ? "selected" : ""}`}
            value="route"
            onClick={(e) => {
              e.preventDefault();
              handleSelectTable(e);
            }}
            type="button"
          >
            <span className={`table-select-button-text ${selectTable === "route" ? "selected-text" : ""}`}>
              Routes
            </span>
          </button>
          <button
            className={`table-select-button ${selectTable === "depot" ? "selected" : ""}`}
            value="depot"
            onClick={(e) => {
              e.preventDefault();
              handleSelectTable(e);
            }}
            type="button"
          >
            <span className={`table-select-button-text ${selectTable === "depot" ? "selected-text" : ""}`}>
              Depots
            </span>
          </button>
          <button
            className={`table-select-button ${selectTable === "charger" ? "selected" : ""}`}
            value="charger"
            onClick={(e) => {
              e.preventDefault();
              handleSelectTable(e);
            }}
            type="button"
          >
            <span className={`table-select-button-text ${selectTable === "charger" ? "selected-text" : ""}`}>
              Public Chargers
            </span>
          </button>
        </div>
      </div>
      <table className="table">
        <thead>
          <tr className="table-header routing-table-header">
            <th style={{ width: '4%' }}>Select</th>
            <th style={{ width: '12%' }}>Route Name</th>
            <th style={{ width: '30%' }}>Origin</th>
            <th style={{ width: '30%' }}>Destination</th>
            <th style={{ width: '8%' }}>Distance</th>
            <th style={{ width: '12%' }}>Start Time</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((route) => {
            const { id, route_name } = route;
            const isSelected = selectedRoutes[id] || false;
            const isHighlighted = lastMapSelection === id;

            // Get raw data if available
            const raw = rawRouteData[id];

            // Extract data from raw route structure
            const routeName = raw?.raw_route_name || route_name || "Loading...";
            const origin = raw?.addresses?.[0] || "Loading...";
            const destination = raw?.addresses?.[raw?.addresses?.length - 1] || "Loading...";
            const distance = formatDistance(raw?.distances?.reduce((a, b) => a+b, 0));
            const startTime = raw?.start_time || "";
            
            return (
              <tr
                key={id}
                className={`table-content ${isSelected ? 'selected' : ''} ${isHighlighted ? 'highlighted' : ''}`}
                onClick={() => onRouteSelect(id)}
              >
                <td style={{ width: '4%' }}>
                  <input
                    type="checkbox"
                    checked={!!selectedRoutes[id]}
                    onChange={() => onRouteSelect(id, false)}
                    className="charger-checkbox"
                  />
                </td>
                <td style={{ width: '12%' }}>{routeName}</td>
                <td style={{ width: '30%' }}>{origin}</td>
                <td style={{ width: '30%' }}>{destination}</td>
                <td style={{ width: '8%' }}>{distance}</td>
                <td style={{ width: '12%' }}>{startTime}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
