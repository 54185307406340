import React, { useState } from 'react';
import './css/AnalyticsDepotSelection.css';
import { LineChart, XAxis, YAxis, CartesianGrid, Line, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from "recharts";
import { AnalyticsTooltip } from './common/AnalyticsTooltip';

export const AnalyticsDepotSelection = ({
    depot,
    onSelect,
    isSelected
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupPosition, setPopupPosition] = useState({ x: 50, y: 50 });
    const [isDragging, setIsDragging] = useState(false);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

    // Use the depot data provided by the backend, with fallbacks if needed
    const depotName = depot.depot_name || `Depot ${depot.depot_id}`;
    const depotAddress = depot.depot_address || "Address information not available";

    const handleClick = () => {
        // Toggle selection - if already selected, unselect it by passing null
        onSelect(isSelected ? null : depot.depot_id);
    };

    const showDetails = (e) => {
        e.stopPropagation();
        setShowPopup(true);
    };

    const closePopup = (e) => {
        e.stopPropagation();
        setShowPopup(false);
    };

    const handleMouseDown = (e) => {
        if (e.target.className === 'popup-header' || e.target.className === 'popup-drag-handle') {
            setIsDragging(true);
            setDragOffset({
                x: e.clientX - popupPosition.x,
                y: e.clientY - popupPosition.y
            });
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            setPopupPosition({
                x: e.clientX - dragOffset.x,
                y: e.clientY - dragOffset.y
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // Prepare charger data for chart
    const prepareChargerData = () => {
        if (!depot.chargers) return [];
        
        return Object.entries(depot.chargers).map(([chargerType, count]) => ({
            type: isNaN(chargerType) ? `${chargerType} kW` : `${parseInt(chargerType)} kW`,
            count: count
        }));
    };

    // Prepare daily cost data for chart
    const prepareCostData = () => {
        if (!depot.daily_costs) return [];
        
        return Object.entries(depot.daily_costs).map(([costType, amount]) => ({
            category: costType.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            amount: amount
        }));
    };

    // Modify the prepareChargingScheduleData to include charging trucks information
    const prepareChargingScheduleData = () => {
        if (!depot) {
            console.log("Missing depot data");
            return [];
        }
        
        if (!depot.charging_schedule) {
            return [];
        }
        
        // Convert time period to time of day format (assuming 15-minute intervals)
        const formattedData = Object.entries(depot.charging_schedule).map(([timePeriod, periodData]) => {
            // Parse the time period as a number to calculate hours and minutes
            const timeIndex = parseInt(timePeriod, 10);
            // Calculate hours and minutes based on 15-minute intervals
            const totalMinutes = timeIndex * 15;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            const timeOfDay = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            
            return {
                timePeriod,
                timeOfDay,
                totalLoad: periodData.total_load || 0,
                electricityRate: periodData.electricity_rate || 0,
                chargingTrucks: periodData.charging_trucks || []
            };
        });
        
        // Sort by time period to ensure chronological order
        formattedData.sort((a, b) => parseInt(a.timePeriod, 10) - parseInt(b.timePeriod, 10));
        
        return formattedData;
    };

    // Format currency values
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(value);
    };

    // Updated to display circuit capacities alongside circuit IDs
    const renderConnectedCircuits = () => {
        if (!depot.connected_circuits || depot.connected_circuits.length === 0) {
            return "None";
        }
        
        return depot.connected_circuits.map((circuit, index) => {
            const capacity = depot.connected_circuit_capacities && 
                             depot.connected_circuit_capacities[index] ? 
                             `${depot.connected_circuit_capacities[index]} kW` : 
                             'N/A kW';
            
            return (
                <span key={`circuit-${index}`}>
                    {circuit} - SCE - {capacity}
                    {index < depot.connected_circuits.length - 1 ? ', ' : ''}
                </span>
            );
        });
    };

    const chargerData = prepareChargerData();
    const costData = prepareCostData();
    const chargingScheduleData = prepareChargingScheduleData();
    const isActive = depot.is_active > 0.5;

    return (
        <div className="analytics-selection-item">
            <button
                className={`depot-selection ${isSelected ? 'depot-selected' : ''} ${isActive ? 'depot-active' : 'depot-inactive'}`}
                onClick={handleClick}
                type="button"
                aria-pressed={isSelected}
            >
                <span className="depot-name">Depot: {depotName}</span>
                <div className="depot-details">
                    <span className="depot-address">Address: {depotAddress}</span>
                    <span className="depot-status">Status: {isActive ? 
                        <span style={{ color: 'green' }}>Active</span> : 
                        <span style={{ color: 'red' }}>Inactive</span>}
                    </span>
                    <span className="depot-power">Peak Power: {depot.peak_power?.toFixed(2)} kW</span>
                    
                    <div className="show-details-container">
                        <button 
                            className="show-details-button" 
                            onClick={showDetails}
                        >
                            Show Details
                        </button>
                    </div>
                </div>
            </button>

            {showPopup && (
                <div 
                    className="depot-popup-overlay" 
                    onClick={closePopup}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                >
                    <div 
                        className="depot-popup-content" 
                        onClick={e => e.stopPropagation()}
                        style={{
                            position: 'absolute',
                            left: `${popupPosition.x}px`,
                            top: `${popupPosition.y}px`,
                            cursor: isDragging ? 'grabbing' : 'auto'
                        }}
                    >
                        <div 
                            className="popup-header"
                            onMouseDown={handleMouseDown}
                            style={{ cursor: 'grab', padding: '10px', background: '#f0f0f0', borderBottom: '1px solid #ddd' }}
                        >
                            <div className="popup-drag-handle" style={{ display: 'inline-block' }}>
                                <h2 style={{ margin: 0, display: 'inline-block' }}>Depot {depotName} Details</h2>
                            </div>
                            <button className="close-popup" onClick={closePopup} style={{ float: 'right' }}>×</button>
                        </div>
                        
                        <div className="depot-info">
                            <p><strong>Address:</strong> {depotAddress}</p>
                            <p><strong>Status:</strong> {isActive ? <span style={{color: 'green'}}>Active</span> : <span style={{color: 'grey'}}>Inactive</span>}</p>
                            <p><strong>Peak Power:</strong> {depot.peak_power?.toFixed(2)} kW</p>
                            <p><strong>Total Daily Cost:</strong> {formatCurrency(depot.total_daily_cost || 0)}</p>
                            <p><strong>Total Upfront Cost:</strong> {formatCurrency(depot.total_upfront_cost || 0)}</p>
                            
                            <div>
                                <p><strong>Connected Circuits:</strong> {renderConnectedCircuits()}</p>
                            </div>
                        </div>
                        
                        <div className="charts-container">
                            {chargerData.length > 0 && (
                                <div className="chart-section">
                                    <h3>Charger Recommendation</h3>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <BarChart data={chargerData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="type" />
                                            <YAxis 
                                                label={{ value: "Count", angle: -90, position: "insideLeft" }}
                                                allowDecimals={false}
                                            />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="count" name="Number of Chargers" fill="#8884d8" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                            
                            {costData.length > 0 && (
                                <div className="chart-section">
                                    <h3>Daily Costs Breakdown</h3>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <BarChart data={costData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="category" angle={-0} textAnchor="middle" height={30} />
                                            <YAxis label={{ value: "Cost ($)", angle: -90, position: "insideLeft" }} />
                                            <Tooltip 
                                                formatter={(value) => formatCurrency(value)}
                                                labelFormatter={(label, payload) => payload[0]?.payload.fullName || label}
                                            />
                                            <Legend />
                                            <Bar dataKey="amount" name="Daily Cost" fill="#82ca9d" />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                            
                            {chargingScheduleData.length > 0 && (
                                <div className="chart-section">
                                    <h3>Depot Charging Schedule</h3>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <LineChart data={chargingScheduleData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="timeOfDay" />
                                            <YAxis label={{ value: "kW", angle: -90, position: "insideLeft" }} />
                                            <Tooltip content={(props) => <AnalyticsTooltip {...props} chartType="charging" />} />
                                            <Legend />
                                            <Line 
                                                type="stepAfter" 
                                                dataKey="totalLoad" 
                                                name="Total Charging Load" 
                                                stroke="#ff7300" 
                                                dot={false} 
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                            
                            {chargingScheduleData.length > 0 && (
                                <div className="chart-section">
                                    <h3>Electricity Rate</h3>
                                    <ResponsiveContainer width="100%" height={200}>
                                        <LineChart data={chargingScheduleData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="timeOfDay" />
                                            <YAxis label={{ value: "$/kWh", angle: -90, position: "insideLeft" }} />
                                            <Tooltip content={(props) => <AnalyticsTooltip {...props} chartType="rate" />} />
                                            <Legend />
                                            <Line 
                                                type="stepAfter" 
                                                dataKey="electricityRate" 
                                                name="Electricity Rate ($/kWh)" 
                                                stroke="#8884d8" 
                                                dot={false} 
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

