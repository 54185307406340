import "../../App.css";
import React, { useState, useEffect } from "react";
import "./css/settings.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { API_ENDPOINTS } from '../../config/apiConfig';

function Settings() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get(API_ENDPOINTS.user, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);




  if (!userData) {
    return <p>Loading ... </p>;
  }

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="pro-calculator-content sidebar-adjustment">
        <div className="dashboard-container">
          <h1 className="title-label">Settings</h1>
        </div>
      </div>
    </div>
  );
}

export default Settings;
