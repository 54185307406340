import React from "react";
import "./LoadingSpinner.css";

export const LoadingSpinner = ({ message }) => {
    return (
        <div className="loading-overlay">
            <div className="spinner-container">
                <div className="spinner"></div>
                <div className="spinner-text">
                    {message || ""}
                </div>
            </div>
        </div>
    );
};