import React from "react";
import { Sidebar, Menu } from "react-pro-sidebar";
import "./ProSidebar.css";
import {
  LuLayoutDashboard,
  LuBatteryCharging,
  LuSettings
} from "react-icons/lu";
import { BsArrowRightSquare, BsArrowLeftSquare } from "react-icons/bs";
import { BiMath } from "react-icons/bi";
import { GoGraph } from "react-icons/go";
import { PiGraph } from "react-icons/pi";
import { SlGraph } from "react-icons/sl";
import { CiDeliveryTruck } from "react-icons/ci";
import { HiOutlineHomeModern } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";
import { SidebarItem } from "./SidebarItem";

export const ProSidebar = ({ collapsed, toggleCollapse }) => {
  return (
    <div className="sidebar-container">
      <Sidebar collapsed={collapsed} toggled={true} onToggle={toggleCollapse}>
        <div className="sidebar-header">
          <div className="sidebar-logo">
            <img
              src="/img/logo.png"
              alt="AMPTRANS logo"
              className={`logo ${collapsed ? "logo-collapsed" : ""}`}
            />
          </div>
        </div>
        <div className="sidebar-content">
          <Menu>
            <div className="sidebar-content-items-group-1">
              <SidebarItem
                collapsed={collapsed}
                icon={<LuLayoutDashboard size={25} />}
                text="Dashboard"
                link="/fleet-planning-pro"
              />
              <div className="sidebar-label">
                <p className="sidebar-label-name">Edit/Add</p>
              </div>
              <SidebarItem
                collapsed={collapsed}
                icon={<LuLayoutDashboard size={25} />}
                text="My Operations"
                link="/my-operations"
              />

              {/* <SidebarItem
                collapsed={collapsed}
                icon={<CiDeliveryTruck size={25} />}
                text="My Fleets"
                link="/fleet"
              /> */}
              {/* <SidebarItem
                collapsed={collapsed}
                icon={<SlGraph size={25} />}
                text="Routes"
                link="/routes"
              />
              <SidebarItem
                collapsed={collapsed}
                icon={<HiOutlineHomeModern size={25} />}
                text="My Depots"
                link="/depot"
              />
              <SidebarItem
                collapsed={collapsed}
                icon={<LuBatteryCharging size={25} />}
                text="Charging Stations"
                link="/chargers"
              /> */}
              
              <SidebarItem
                collapsed={collapsed}
                icon={<PiGraph size={25} />}
                text="Inputs"
                link="/parameters"
              />
              <div className="sidebar-label">
                <p className="sidebar-label-name">Tools</p>
              </div>
              {/* <SidebarItem
                collapsed={collapsed}
                icon={<BiMath size={25} />}
                text="Calculator"
                link="/optimization"
              /> */}
              <SidebarItem
                collapsed={collapsed}
                icon={<GoGraph size={25} />}
                text="Analytics"
                link="/analytics"
              />
            </div>
            <div className="sidebar-content-items-group-2">
              <SidebarItem
                collapsed={collapsed}
                icon={<CgProfile size={25} />}
                text="Profile"
                link="/profile"
              />
              <SidebarItem
                collapsed={collapsed}
                icon={<LuSettings size={25} />}
                text="Settings"
                link="/settings"
              />
            </div>
            <div className="sidebar-footer">
              <button onClick={toggleCollapse} className="collapse-btn">
                {collapsed ? (
                  <BsArrowRightSquare size={25} />
                ) : (
                  <>
                    <BsArrowLeftSquare size={25} />
                    <span>Collapse</span>
                  </>
                )}
              </button>
            </div>
          </Menu>
        </div>
      </Sidebar>
    </div>
  );
};
