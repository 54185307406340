import React, { useState, useEffect, useMemo } from "react";
import { MapContainer, TileLayer, Polyline, Marker, Popup } from "react-leaflet";
import axios from "axios";
import { API_ENDPOINTS } from "../../../config/apiConfig";
import L from "leaflet";

const CIRCUIT_COLORS = [
  '#FF0000', // Red
  '#00FF00', // Green
  '#0000FF', // Blue
  '#FFA500', // Orange
  '#800080', // Purple
  '#00FFFF', // Cyan
  '#FF00FF', // Magenta
  '#FFD700', // Gold
  '#008000', // Dark Green
  '#4B0082', // Indigo
];

const markerIcons = {
  depot: {
    default: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [0.1, 0.1],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [0.1, 0.1],
    }),
    selected: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    })
  },
  chargingStation: {
    default: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [0.1, 0.1],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [0.1, 0.1],
    }),
    selected: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    })
  }
};

export const AnalyticsMap = ({ 
  routes, 
  selectedRouteId = null,
  selectedDepot: selectedDepotId = null,
  selectedChargingStation: selectedChargingStationId = null,
  onChargingStationClick,
  showChargingStations = true
}) => {
  // routes: an array of route objects containing at least { id, raw_route }
  // selectedRouteId: the route to display (if provided, only that route is shown)
  const [analyticsRoutes, setAnalyticsRoutes] = useState([]);
  const [circuitData, setCircuitData] = useState({});
  const [depots, setDepots] = useState([]);
  const [chargingStations, setChargingStations] = useState([]);
  const [loading, setLoading] = useState(false);

  // Find the selected depot object from the depots array
  // const selectedDepot = depots.find(depot => depot.depot_id === selectedDepotId);

  // Fetch depots on mount
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    let isMounted = true;  // Add mounted flag

    const fetchDepots = async () => {
      try {

        const response = await axios.get(API_ENDPOINTS.getDepot, {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });

        if (Array.isArray(response.data)) {
          if (isMounted) {
            setDepots(response.data);
          }
        }
      } catch (error) {
        if (isMounted) {  // Only log error if still mounted
          console.error('Error fetching depots:', error);
          if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
          }
        }
      }
    };

    if (authToken) {
      fetchDepots();
    }

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  // Find the selected depot object from the depots array
  const selectedDepot = useMemo(() => 
    depots.find(depot => depot.depot_id === selectedDepotId),
    [depots, selectedDepotId]
  );

  // Fetch circuit data when selectedDepotId changes
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    let isMounted = true;
    const controller = new AbortController();

    const fetchCircuitData = async () => {
      if (!selectedDepotId || !authToken) {
        console.log('Missing depot ID or auth token');
        return;
      }

      if (isMounted) {
        setLoading(true);
      }

      try {
        // Find the selected depot
        const selectedDepotData = depots.find(d => d.depot_id === selectedDepotId);

        
        if (!selectedDepotData) {
          console.warn('Selected depot not found in depots array:', selectedDepotId);
          return;
        }
        
        
        // Extract circuit names from circuit_info
        let circuitNames = [];
        
        if (selectedDepotData.circuit_info && Array.isArray(selectedDepotData.circuit_info)) {
          // Extract the circuit names from the circuit_info array
          circuitNames = selectedDepotData.circuit_info.map(info => info.circuit);
        } else {
          console.warn('No circuit_info array found for depot:', selectedDepotId);
          return;
        }
        
        if (!circuitNames || circuitNames.length === 0) {
          console.warn('No circuit names found for depot:', selectedDepotId);
          return;
        }
        
        
        // Send circuit_names in the request body
        const response = await axios.post(API_ENDPOINTS.getCircuits, {
          circuit_names: circuitNames
        }, {
          headers: {
            Authorization: `Token ${authToken}`,
            'Content-Type': 'application/json'
          },
          signal: controller.signal
        });

        if (isMounted) {
          if (response.data && response.data.circuits && Array.isArray(response.data.circuits)) {
            setCircuitData(prevData => ({
              ...prevData,
              [selectedDepotId]: response.data.circuits
            }));
          }
        }
      } catch (error) {
        if (error.name === 'CanceledError') {
          console.log('Request was cancelled');
        } else if (isMounted) {
          console.error('Error fetching circuit data:', error);
          if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
          }
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchCircuitData();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [selectedDepotId, depots]);

  // Fetch charging stations on mount
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    let isMounted = true;
    
    const fetchChargingStations = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getCharger, {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });

        if (Array.isArray(response.data)) {
          if (isMounted) {
            setChargingStations(response.data);
          }
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching charging stations:', error);
          if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
          }
        }
      }
    };

    if (authToken) {
      fetchChargingStations();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  // Cleanup effect
  useEffect(() => {
    let isMounted = true;
    return () => {
      isMounted = false;
    };
  }, [selectedDepotId, selectedDepot, selectedChargingStationId, depots, chargingStations, circuitData, loading]);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");

    const fetchRoutes = async () => {
      if (routes && Object.keys(routes).length > 0) {
        const fetchedRoutes = [];
        // Use Object.values to convert the routes object to an array
        for (const route of Object.values(routes)) {
          try {
            const response = await axios.get(API_ENDPOINTS.getRawRoute, {
              params: { route_id: route.route_id },
              headers: {
                Authorization: `Token ${authToken}`,
              },
            });

            if (response.data && response.data.links && Array.isArray(response.data.links)) {
              // Process all links in the route, not just the first one
              const allPositions = [];
              
              // Iterate through all links in the route
              response.data.links.forEach(link => {
                if (link && link.coordinates) {
                  // Convert coordinates from [lon, lat] to [lat, lon] for Leaflet
                  const positions = link.coordinates.map(coord => [coord[1], coord[0]]);
                  allPositions.push(positions);
                }
              });
              
              fetchedRoutes.push({
                id: route.route_id,
                positions: allPositions, // Store all positions from all links
              });
            }
          } catch (error) {
            console.error(`Error fetching raw route for route ${route.route_id}:`, error);
          }
        }
        setAnalyticsRoutes(fetchedRoutes);
      } else {
        setAnalyticsRoutes([]);
      }
    };

    fetchRoutes();
  }, [routes]);

  // Determine the color for a route based on its electric status.
  // Green for electric routes, red for non-electric routes.
  const getColorForRoute = (routeId) => {
    const route = routes[routeId];
    if (route && route.is_electric === true) {
      return "#00FF00"; // Green for electric routes
    }
    return "#FF0000"; // Red for non-electric routes
  };

  // console.log('circuitData:', circuitData);
  // console.log('selectedDepot:', selectedDepot);

  const renderCircuitLines = () => {
    if (!selectedDepotId || !circuitData[selectedDepotId]) {
      console.log('No circuit data available for rendering');
      return [];
    }

    const lines = [];
    
    circuitData[selectedDepotId].forEach((circuit, circuitIndex) => {
      if (circuit?.geometry?.coordinates) {
        const circuitColor = CIRCUIT_COLORS[circuitIndex % CIRCUIT_COLORS.length];
        circuit.geometry.coordinates.forEach((lineString, lineIndex) => {
          if (Array.isArray(lineString) && lineString.length >= 2) {
            const positions = lineString.map(coord => [coord[1], coord[0]]);
            lines.push(
              <Polyline
                key={`circuit-${selectedDepotId}-${circuitIndex}-${lineIndex}`}
                positions={positions}
                color={circuitColor}
                weight={3}
                opacity={0.8}
              >
                <Popup>
                  <div className="circuit-popup">
                    <h5>Circuit: {circuit.circuit_name || 'Unnamed Circuit'}</h5>
                    <table>
                      <tbody>
                        <tr>
                          <td>Capacity</td>
                          <td>{circuit.capacity || 'N/A'} kW</td>
                        </tr>
                        <tr>
                          <td>Voltage</td>
                          <td>{circuit.circuit_voltage || 'N/A'} kV</td>
                        </tr>
                        {circuit.substation_name && (
                          <tr>
                            <td>Substation</td>
                            <td>{circuit.substation_name.replace(/Kv/g, 'kV')}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Popup>
              </Polyline>
            );
          }
        });
      }
    });
    return lines;
  };

  // Add console.log to debug route filtering
  // console.log('selectedRouteId:', selectedRouteId);
  // console.log('analyticsRoutes:', analyticsRoutes);

  // If a selectedRouteId is provided, filter analyticsRoutes to display only that route.
  // If selectedRouteId is null, don't display any routes
  const routesToDisplay = selectedRouteId
    ? analyticsRoutes.filter(route => route.id === selectedRouteId)
    : [];

  // console.log('routesToDisplay:', routesToDisplay);

  // Helper function to validate coordinates
  const isValidCoordinate = (lat, lng) => {
    return typeof lat === 'number' && 
           typeof lng === 'number' && 
           !isNaN(lat) && 
           !isNaN(lng) &&
           lat >= -90 && 
           lat <= 90 && 
           lng >= -180 && 
           lng <= 180;
  };

  // Helper function to handle charging station click
  const handleChargingStationClick = (stationId) => {
    if (onChargingStationClick) {
      // Check if the station is already selected, if so, pass null to deselect it
      const shouldDeselect = stationId === selectedChargingStationId;
      onChargingStationClick(shouldDeselect ? null : stationId, true); // true indicates it's from the map
    }
  };



  return (
    <div className="map-container">
      <MapContainer
        center={[33.9, -117.5]}
        zoom={10}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; OpenStreetMap contributors'
        />
        {loading && (
          <div className="map-loading-overlay">
            Loading circuit data...
          </div>
        )}
        
        {/* Only render circuit lines if we have a selectedDepotId */}
        {selectedDepotId && renderCircuitLines()}
        
        {depots.map((depot) => {
          // Convert coordinates to numbers and validate them
          const lat = parseFloat(depot.lat);
          const lng = parseFloat(depot.lon);

          if (!isValidCoordinate(lat, lng)) {
            console.warn(`Invalid coordinates for depot ${depot.depot_id}:`, {
              latitude: depot.lat,
              longitude: depot.lon
            });
            return null;  // Skip this marker
          }

          return (
            <Marker
              key={`depot-${depot.depot_id}`}
              position={[lat, lng]}
              icon={depot.depot_id === selectedDepotId 
                ? markerIcons.depot.selected 
                : markerIcons.depot.default}
            >
              <Popup>
                <div>
                  <h5>Depot: {depot.name || depot.depot_id}</h5>
                  <p>ID: {depot.depot_id}</p>
                  {depot.address && <p>Address: {depot.address}</p>}
                  <p>Coordinates: {lat.toFixed(6)}, {lng.toFixed(6)}</p>
                </div>
              </Popup>
            </Marker>
          );
        })}
        
        {/* Render charging stations if showChargingStations is true */}
        {showChargingStations && chargingStations.map((station) => {
          // Convert coordinates to numbers and validate them
          const lat = parseFloat(station.lat);
          const lng = parseFloat(station.lon);
          
          // Skip stations with missing IDs to prevent duplicate keys
          if (!station.cs_id) {
            return null;
          }

          if (!isValidCoordinate(lat, lng)) {
            console.warn(`Invalid coordinates for charging station ${station.cs_id}:`, {
              latitude: station.lat,
              longitude: station.lon
            });
            return null;  // Skip this marker
          }

          return (
            <Marker
              key={`charging-station-${station.cs_id}`}
              position={[lat, lng]}
              icon={station.cs_id === selectedChargingStationId 
                ? markerIcons.chargingStation.selected 
                : markerIcons.chargingStation.default}
              eventHandlers={{
                click: () => handleChargingStationClick(station.cs_id)
              }}
            >
              <Popup>
                <div>
                  <h5>Charging Station: {station.name || station.cs_id}</h5>
                  <p>ID: {station.cs_id}</p>
                  <p>Type: {station.charger_type || 'Standard'}</p>
                  <p>Power: {station.power_capacity || 'N/A'} kW</p>
                  <p>Coordinates: {lat.toFixed(6)}, {lng.toFixed(6)}</p>
                </div>
              </Popup>
            </Marker>
          );
        })}
        
        {/* Only display routes if we have a selected route */}
        {routesToDisplay.map((route) => (
          // If positions is an array of arrays (multiple links)
          route.positions.map((positions, linkIndex) => (
            <Polyline
              key={`analytics-route-${route.id}-link-${linkIndex}`}
              positions={positions}
              color={getColorForRoute(route.id)}
              weight={4}
              opacity={0.8}
            >
              <Popup>
                <div>
                  <h5>Route: {route.id}</h5>
                  <p>Link: {linkIndex + 1}</p>
                  <p>Status: {routes[route.id]?.is_electric === true ? 'Electric' : 'Non-Electric'}</p>
                </div>
              </Popup>
            </Polyline>
          ))
        ))}
      </MapContainer>
    </div>
  );
};