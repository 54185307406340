import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, useMap, Marker, Polyline, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./CustomMap.css";
import "leaflet-routing-machine";
import axios from "axios";
import { API_ENDPOINTS } from '../../config/apiConfig';

import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-shadow.png";

const CIRCUIT_COLORS = [
  '#FF0000', // Red
  '#00FF00', // Green
  '#0000FF', // Blue
  '#FFA500', // Orange
  '#800080', // Purple
  '#00FFFF', // Cyan
  '#FF00FF', // Magenta
  '#FFD700', // Gold
  '#008000', // Dark Green
  '#4B0082', // Indigo
];

const markerIcons = {
  charger: {
    default: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
    selected: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    })
  },
  depot: {
    default: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    }),
    selected: new L.Icon({
      iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
      shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    })
  }
};

// Define a fixed array of 20 distinct colors for routes
const routeColors = [
  '#FF5733', // Red-Orange
  '#33FF57', // Green
  '#3357FF', // Blue
  '#FF33F5', // Pink
  '#F5FF33', // Yellow
  '#33FFF5', // Cyan
  '#FF5733', // Coral
  '#8C33FF', // Purple
  '#FF8C33', // Orange
  '#33FF8C', // Mint
  '#338CFF', // Sky Blue
  '#FF338C', // Rose
  '#8CFF33', // Lime
  '#F533FF', // Magenta
  '#33F5FF', // Turquoise
  '#FFA333', // Amber
  '#33FFA3', // Seafoam
  '#A333FF', // Violet
  '#FFA3A3', // Light Coral
  '#A3FFA3'  // Light Green
];

const RoutingControl = ({ coords, highlightedRoute, routeColorMap, setRouteColorMap }) => {
  const map = useMap();
  const { routeId } = coords; // Extract routeId from coords

  const processedCoordinates = [];
  if (coords && coords.lat && coords.lon) {
    for (let i = 0; i < coords.lat.length; i++) {
      if (coords.lat[i] !== null && coords.lon[i] !== null) {
        processedCoordinates.push([coords.lat[i], coords.lon[i]]);
      }
    }
  }

  useEffect(() => {
    if (!map || processedCoordinates.length < 2) return;

    try {
      // Get or assign a color for this route
      let routeColor;
      
      // Check if this route already has a color assigned
      if (routeColorMap[routeId]) {
        routeColor = routeColorMap[routeId];
      } else {
        // Assign a new color from our fixed palette
        const colorIndex = Object.keys(routeColorMap).length % routeColors.length;
        routeColor = routeColors[colorIndex];
        
        // Update the route color map
        setRouteColorMap(prev => ({
          ...prev,
          [routeId]: routeColor
        }));
      }

      const polyline = L.polyline(processedCoordinates, {
        color: routeColor,
        weight: 6,
        opacity: 1,
        smoothFactor: 1,
      });

      polyline.addTo(map);

      const markers = [];

      if (processedCoordinates.length > 0) {
        // Start coordinate: White dot
        markers.push(
          L.circleMarker(processedCoordinates[0], {
            radius: 6,
            color: "#000000",
            weight: 2,
            fillColor: "#ffffff",
            fillOpacity: 1,
          }).addTo(map)
        );
      }

      if (processedCoordinates.length > 1) {
        // Last stop: Red marker
        markers.push(
          L.marker(processedCoordinates[processedCoordinates.length - 1], {
            icon: L.icon({
              iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
              shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
              iconSize: [25, 41],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41],
            }),
          }).addTo(map)
        );
      }

      return () => {
        map.removeLayer(polyline);
        markers.forEach((marker) => map.removeLayer(marker));
      };
    } catch (error) {
      console.error("Error setting up polyline:", error);
    }
  }, [map, coords, highlightedRoute, routeId, routeColorMap, setRouteColorMap]);

  return null;
};

export const CustomMap = ({
  selectedRoutes,
  selectedEntities = {},
  selectedChargers = {},
  circuitData = {},
  onMarkerClick,
  onChargerClick,
  highlightedRoute,
  depotList = [],
  refreshTrigger = 0,
}) => {
  const [routeColorMap, setRouteColorMap] = useState({});
  const [routeCheckbox, setRouteCheckbox] = useState(true);
  const [depotCheckbox, setDepotCheckbox] = useState(true);
  const [chargerCheckbox, setChargerCheckbox] = useState(true);
  const [mapHeight, setMapHeight] = useState(null); // Track custom height
  const [isDragging, setIsDragging] = useState(false);
  const mapContainerRef = useRef(null);

  const [processedRoutes, setProcessedRoutes] = useState([]);
  const [chargers, setChargers] = useState([]);
  const [depots, setDepots] = useState([]);

  const [satelliteView, setSatelliteView] = useState(false);

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    async function fetchSelectedRoutes() {
      if (!routeCheckbox) {
        setProcessedRoutes([]);
        return;
      }

      try {
        const response = await axios.get(API_ENDPOINTS.getProcessedRoutes, {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });

        if (response.data && response.data.length > 0) {
          const routesFromAPI = response.data;
          const processedRoutesData = [];

          // Extract all selected route ids (as strings)
          const selectedRouteIds = Object.entries(selectedRoutes)
            .filter(([_, isSelected]) => isSelected)
            .map(([id]) => id);

          for (const routeId of selectedRouteIds) {
            const currentRoute = routesFromAPI.find(
              (route) => route.id.toString() === routeId
            );

            if (currentRoute) {
              try {
                const rawResponse = await axios.get(API_ENDPOINTS.getRawRoute, {
                  params: { route_id: currentRoute.id },
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                });

                if (rawResponse.data?.links?.length) {
                  // const coords = rawResponse.data.links[0].coordinates;
                  // processedRoutesData.push({
                  //   lat: coords.map((coord) => coord[1]),
                  //   lon: coords.map((coord) => coord[0]),
                  //   routeId: currentRoute.id,
                  // });
                  rawResponse.data.links.forEach((link) => {
                    if (link?.coordinates) {
                      processedRoutesData.push({
                        lat: link.coordinates.map((coord) => coord[1]),
                        lon: link.coordinates.map((coord) => coord[0]),
                        routeId: currentRoute.id,
                      });
                    }
                  });
                }
              } catch (error) {
                console.error(`Error fetching raw route for id ${routeId}:`, error);
              }
            }
          }

          setProcessedRoutes(processedRoutesData);
        }
      } catch (error) {
        console.error("Error fetching routes: ", error);
        setProcessedRoutes([]);
      }
    }
    fetchSelectedRoutes();
  }, [routeCheckbox, selectedRoutes, authToken]);

  useEffect(() => {
    axios
      .get(API_ENDPOINTS.getCharger, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setChargers(response.data);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  useEffect(() => {
    const fetchDepots = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getDepot, {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        });
        setDepots(response.data);
      } catch (error) {
        console.error("Error fetching depots:", error);
      }
    };

    if (authToken) {
      fetchDepots();
    }
  }, [authToken, refreshTrigger]);

  const getMarkerIcon = (item, type) => {
    if (type === 'depot') {
      const isSelected = selectedEntities[item.depot_id];
      return isSelected ? markerIcons[type].selected : markerIcons[type].default;
    } else if (type === 'charger') {
      const isSelected = selectedChargers[item.cs_id];
      return isSelected ? markerIcons[type].selected : markerIcons[type].default;
    }
    return markerIcons[type].default;
  };

  const renderCircuitLines = () => {
    const lines = [];
    Object.entries(selectedEntities).forEach(([depotId, isSelected]) => {
      if (isSelected && circuitData[depotId]) {
        circuitData[depotId].forEach((circuit, circuitIndex) => {
          const circuitColor = CIRCUIT_COLORS[circuitIndex % CIRCUIT_COLORS.length];
          circuit.geometry.coordinates.forEach((lineString, lineIndex) => {
            const positions = lineString.map(coord => [coord[1], coord[0]]);
            lines.push(
              <Polyline
                key={`circuit-${depotId}-${circuitIndex}-${lineIndex}`}
                positions={positions}
                color={circuitColor}
                weight={3}
                opacity={0.8}
                eventHandlers={{
                  click: (e) => {
                    L.DomEvent.stopPropagation(e);
                  },
                }}
              >
                <Popup>
                  <div className="circuit-popup">
                    <h5>Circuit: {circuit.circuit_name}</h5>
                    <table>
                      <tbody>
                        <tr>
                          <td>Capacity</td>
                          <td>{circuit.capacity} kW</td>
                        </tr>
                        <tr>
                          <td>Voltage</td>
                          <td>{circuit.circuit_voltage} kV</td>
                        </tr>
                        <tr>
                          <td>Substation</td>
                          <td>{circuit.substation_name?.replace(/Kv/g, 'kV')}</td>
                        </tr>
                        <tr>
                          <td>Last Updated</td>
                          <td>{new Date(circuit.date_last_update).toLocaleDateString()}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Popup>
              </Polyline>
            );
          });
        });
      }
    });
    return lines;
  };

  // Handle resize drag start
  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  // Handle resize drag movement
  const handleMouseMove = (e) => {
    if (!isDragging || !mapContainerRef.current) return;
    
    const containerRect = mapContainerRef.current.getBoundingClientRect();
    const parentElement = mapContainerRef.current.parentElement;
    const parentRect = parentElement.getBoundingClientRect();
    
    // Calculate new height based on mouse position
    const newHeight = Math.max(
      200, // Minimum height
      Math.min(
        window.innerHeight * 0.9, // Maximum height (90% of viewport)
        containerRect.height + (e.clientY - (containerRect.top + containerRect.height))
      )
    );
    
    setMapHeight(newHeight);
  };

  // Handle resize drag end
  const handleMouseUp = () => {
    setIsDragging(false);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  // Handle touch events for mobile
  const handleTouchStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);
  };

  const handleTouchMove = (e) => {
    if (!isDragging || !mapContainerRef.current) return;
    
    const touch = e.touches[0];
    const containerRect = mapContainerRef.current.getBoundingClientRect();
    
    const newHeight = Math.max(
      200,
      Math.min(
        window.innerHeight * 0.9,
        containerRect.height + (touch.clientY - (containerRect.top + containerRect.height))
      )
    );
    
    setMapHeight(newHeight);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd);
  };

  // Clean up event listeners on unmount
  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  // Modified marker click handler
  const handleMarkerClick = (depotId, fromMap = false) => {
    // Check if we received an object (batch selection from table)
    if (typeof depotId === 'object') {
      // This is a batch operation from the "select all" checkbox
      if (onMarkerClick) onMarkerClick(depotId, true);
    } else {
      // This is a single selection
      if (onMarkerClick) onMarkerClick(depotId, fromMap);
    }
  };

  // Modified charger click handler
  const handleChargerClick = (chargerId, fromMap = false) => {
    // Check if we received an object (batch selection from table)
    if (typeof chargerId === 'object') {
      // This is a batch operation from the "select all" checkbox
      if (onChargerClick) onChargerClick(chargerId, true);
    } else {
      // This is a single selection
      if (onChargerClick) onChargerClick(chargerId, fromMap);
    }
  };

  if (!window.L) {
    return <div>Loading map library...</div>;
  }

  // Determine the style for the map container
  const mapContainerStyle = {
    height: mapHeight ? `${mapHeight}px` : undefined,
  };

  return (
    <div 
      ref={mapContainerRef}
      className="map-container" 
      style={mapContainerStyle}
    >
      <MapContainer
        key={satelliteView ? "satellite" : "osm"}
        center={[33.9, -117.5]}
        zoom={10}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
      >
        {/* <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        /> */}

        {/* <TileLayer
          url="https://{s}.tile.stamen.com/terrain/{z}/{x}/{y}.jpg"
          attribution='&copy; <a href="https://stamen.com/">Stamen Design</a>, <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        /> */}

        <TileLayer
          url={satelliteView ? 
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" : 
            "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          }
          attribution={satelliteView ? 
            '&copy; <a href="https://www.esri.com/en-us/arcgis/products/arcgis-online/overview">Esri</a>' : 
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }
        />
        {renderCircuitLines()}

        {/* Render depot markers */}
        {depotCheckbox && depots.map((depot, index) => (
          <Marker
            key={`depot-${depot.depot_id || index}`}
            position={[depot.lat, depot.lon]}
            icon={getMarkerIcon(depot, 'depot')}
            zIndexOffset={selectedEntities[depot.depot_id] ? 1000 : 0}
            eventHandlers={{
              click: () => handleMarkerClick(depot.depot_id, true),
            }}
          >
            <Popup>
              <div>
                <h4>Depot</h4>
                {depot.name && <p>Name: {depot.name}</p>}
                <p>ID: {depot.depot_id}</p>
              </div>
            </Popup>
          </Marker>
        ))}

        {/* Render charger markers */}
        {chargerCheckbox && chargers.map((charger, index) => (
          <Marker
            key={`charger-${charger.cs_id || index}-${charger.lat}-${charger.lon}`}
            position={[charger.lat, charger.lon]}
            icon={getMarkerIcon(charger, 'charger')}
            zIndexOffset={selectedChargers[charger.cs_id] ? 1000 : 0}
            eventHandlers={{
              click: () => handleChargerClick(charger.cs_id, true),
            }}
          >
            <Popup>
              <div>
                <h4>Charging Station</h4>
                <p>ID: {charger.cs_id}</p>
                <p>{charger.owner} - {charger.address}</p>
                <p>Chargers: {charger.chargers}</p>
                <p>Charging capacity: {charger.powers}</p>
              </div>
            </Popup>
          </Marker>
        ))}

        {/* Route Display: map over each processed route */}
        {routeCheckbox && processedRoutes && processedRoutes.length > 0 &&
          processedRoutes.map(route => (
            <RoutingControl
              key={`route-${route.routeId}`}
              coords={route}
              highlightedRoute={highlightedRoute}
              routeColorMap={routeColorMap}
              setRouteColorMap={setRouteColorMap}
            />
          ))
        }
      </MapContainer>
      <div className="map-overlay">
        <h3>Features</h3>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={routeCheckbox}
            onChange={(e) => setRouteCheckbox(e.target.checked)}
          />
          <span className="map-checkbox-description">Routes</span>
        </label>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={depotCheckbox}
            onChange={(e) => setDepotCheckbox(e.target.checked)}
          />
          <span className="map-checkbox-description">
            Depots
            <img 
              src= "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png"
              alt="Depot"
              style={{ width: "10px", height: "15px", marginLeft: "5px" }}
            />
          </span>
        </label>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={chargerCheckbox}
            onChange={(e) => setChargerCheckbox(e.target.checked)}
          />
          <span className="map-checkbox-description">
            Charging Stations
            <img 
              src= "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png"
              alt="Chargers"
              style={{ width: "10px", height: "15px", marginLeft: "5px" }}
            />
          </span>
        </label>
        <h3>Map Layer</h3>
        <label className="overlay-entry">
          <input
            type="checkbox"
            className="checkbox-overlay"
            checked={satelliteView}
            onChange={(e) => setSatelliteView(e.target.checked)}
          />
          <span className="map-checkbox-description">Satellite View</span>
        </label>
      </div>
      
      {/* Resize handle - now spans the full width */}
      <div 
        className="map-resize-handle"
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      />
    </div>
  );
};