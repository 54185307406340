import "../../App.css";
import React, { useState, useEffect } from "react";
import './css/analytics.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProSidebar } from "../../components/pro_calculator/ProSidebar";
import { API_ENDPOINTS } from "../../config/apiConfig.js";
import { AnalyticsHighlights } from "../../components/pro_calculator/analytics/AnalyticsHighlights.jsx";
import { AnalyticsOperationalRecommendations } from "../../components/pro_calculator/analytics/AnalyticsOperationalRecommendations.jsx";
import { AnalyticsFinancials } from "../../components/pro_calculator/analytics/AnalyticsFinancials.jsx";
import { AnalyticsCaseHistory } from "../../components/pro_calculator/analytics/AnalyticsCaseHistory.jsx";
import calculatorParameters from "../../data/calculator_parameters.json";

function Analytics() {
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [optimizationResults, setOptimizationResults] = useState(null);
  const [error, setError] = useState(null);
  const [highlightMapRoute, setHighlightMapRoute] = useState(null);
  const [selectedInfoType, setSelectedInfoType] = useState("routes");
  const [highlightedDepot, setHighlightedDepot] = useState(null);
  const [highlightedChargingStation, setHighlightedChargingStation] = useState(null);
  const [showUnusedStations, setShowUnusedStations] = useState(false);
  const [selectedRecommendedTruck, setSelectedRecommendedTruck] = useState(null);
  const [selectedCase, setSelectedCase] = useState(null);
  const [loading, setLoading] = useState(true);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const navigate = useNavigate();

  const authToken = localStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get(API_ENDPOINTS.user, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setAuthenticated(true);
        setUserData(response.data);
      })
      .catch((error) => {
        setAuthenticated(false);
        navigate("/login");
      });
  }, [navigate, authToken]);

  useEffect(() => {
    setLoading(true);
    axios.get(API_ENDPOINTS.getLatestOptimization, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    })
      .then((response) => {
        setOptimizationResults(response.data);
      })
      .catch((error) => {
        console.error("Error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authToken]);

  // Handle viewing a specific case from history
  const handleViewCase = (caseData) => {
    setSelectedCase(caseData);
    setOptimizationResults(caseData);
    window.scrollTo(0, 0); // Scroll to the top to show results
  };

  if (!userData || (!optimizationResults && loading)) {
    return <p>Loading...</p>;
  }

  return (
    <div className="container-flex-row">
      <ProSidebar collapsed={collapsed} toggleCollapse={toggleCollapse} />
      <div className="container-flex-col sidebar-adjustment">
        <div style={{height: "10vh"}}></div>
        {selectedCase && (
          <div className="selected-case-banner">
            <h3>Viewing case: {selectedCase.case_name}</h3>
            <button 
              className="return-to-latest" 
              onClick={() => {
                setLoading(true);
                axios.get(API_ENDPOINTS.getLatestOptimization, {
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                })
                  .then((response) => {
                    setSelectedCase(null);
                    setOptimizationResults(response.data);
                  })
                  .catch((error) => {
                    console.error("Error", error);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              Return to Latest
            </button>
          </div>
        )}
        <h2>Case Highlights</h2>
        <AnalyticsHighlights
          optimizationResults={optimizationResults}
          selectedRecommendedTruck={selectedRecommendedTruck}
          setSelectedRecommendedTruck={setSelectedRecommendedTruck}
        />

        <div style={{height: "5vh"}}></div>
        <h2>Financial Analysis</h2>
        <AnalyticsFinancials optimizationResults={optimizationResults} />
        
        <div style={{height: "5vh"}}></div>
        <h2>Operational Analysis</h2>
        <AnalyticsOperationalRecommendations
          optimizationResults={optimizationResults}
          selectedInfoType={selectedInfoType}
          setSelectedInfoType={setSelectedInfoType}
          highlightMapRoute={highlightMapRoute}
          setHighlightMapRoute={setHighlightMapRoute}
          highlightedDepot={highlightedDepot}
          setHighlightedDepot={setHighlightedDepot}
          highlightedChargingStation={highlightedChargingStation}
          setHighlightedChargingStation={setHighlightedChargingStation}
          showUnusedStations={showUnusedStations}
          setShowUnusedStations={setShowUnusedStations}
        />

        <div style={{height: "5vh"}}></div>
        <h2>Case History</h2>
        <AnalyticsCaseHistory onViewCase={handleViewCase} />
      </div>
    </div>
  );
}

export default Analytics;
